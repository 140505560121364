import React from "react";

const ModalNotConnect = () => {
  return (
    <div>
      <h2 className="heading-400 text-error-bein">
        Bạn chưa đăng nhập ví Metamask
      </h2>
      <p className="heading-100">
        Bạn cần đăng nhập ví Metamask trước để có thể xem các thông tin và thực
        hiện giao dịch.
        <br /> Đọc hướng dẫn tham gia Beincom Private Contribution {" "}
        <a
        className='link-color'
          target="_blank"
          href={process.env.REACT_APP_LINK_GUID_BEINCOM || ''}
          rel="noopener noreferrer"
        >
          tại đây
        </a>
      </p>
    </div>
  );
};

export default ModalNotConnect;
