import { DURATION_FOR_NEW_HOLDER, SECONDS_IN_DAY } from 'constants/common'

export const convertTimeStampToDate = (timestamp) => {
  return new Date(timestamp * 1000)
}
export const convertDateToTimeStamp = (date) => {
  return Math.round(date.getTime() / 1000)
}
/**
 * @returns seconds
 */
export const durationTime = (
  startTimeStamp,
  endDay = DURATION_FOR_NEW_HOLDER
) => {
  //convert date now from milliseconds to seconds
  const now = new Date().getTime() / 1000
  return endDay * SECONDS_IN_DAY - (now - startTimeStamp)
}
