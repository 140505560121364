import { useEffect, useLayoutEffect, useRef } from "react"

export const useIsomorphicLayoutEffect =
    typeof window !== 'undefined' ? useLayoutEffect : useEffect

export function useEventListener(
    eventName,
    handler,
    element
) {
    // Create a ref that stores handler
    const savedHandler = useRef(handler)

    useIsomorphicLayoutEffect(() => {
        savedHandler.current = handler
    }, [handler])

    useEffect(() => {
        // Define the listening target
        const targetElement= element?.current || window
        if (!(targetElement && targetElement.addEventListener)) {
            return
        }

        // Create event listener that calls handler function stored in ref
        const eventListener= (event) =>
            savedHandler.current(event)

        targetElement.addEventListener(eventName, eventListener)

        // Remove event listener on cleanup
        // eslint-disable-next-line consistent-return
        return () => {
            targetElement.removeEventListener(eventName, eventListener)
        }
    }, [eventName, element])
}

export function useClickOutside(
    ref,
    handler,
    mouseEvent = 'mouseup'
) {
    useEventListener(mouseEvent, (event) => {
        const el = ref?.current

        // Do nothing if clicking ref's element or descendent elements
        if (!el || el.contains(event.targe)) {
            return
        }

        handler(event)
    })
}