import { Progress } from 'antd'
import CardTime from 'components/CardTime/CardTime'
import React, { memo, useEffect, useMemo, useRef } from 'react'
import moment from 'moment'
import { getInfoRound } from 'utils/contract'
import { clsx } from 'clsx'
import AddToCalendarHOC, { SHARE_SITES } from 'react-add-to-calendar-hoc'
import SelectCalendar from './SelectCalendar'
import ButtonAddCalendar from './ButtonAddCalendar'
import { useCountDownTimer } from 'hooks/time'
import { useGetManagementContractQuery } from 'hooks/useGetManagementContractQuery'
import { updateContributeInfoAction } from 'redux/actions/updateContributeInfo'
import { useDispatch } from 'react-redux'

const RoundView = () => {
	const dispatch = useDispatch()

	const {
		data: { endTime, startTime },
		isLoading,
	} = useGetManagementContractQuery()

	const startTimeConvert = moment(startTime).format('HH:mm DD/MM/YYYY')
	const { inRound, endRound, startRound } = getInfoRound(startTime, endTime)
	const refButtonAddCalendar = useRef(null)
	const countTime = inRound ? endTime : startTime

	const time = useCountDownTimer(countTime, endRound)

	const isImg = !endRound

	// const titleEndRound = <>Đã kết thúc!</>
	const titleInRound = `Kết thúc sau`
	const titleStartRound = (
		<>
			<div>
				Sẽ diễn ra vào <span className='bein-text-bgt'>{startTimeConvert}</span>
			</div>
		</>
	)
	const startDatetime = moment(startTime).utc()
	const endDatetime = startDatetime.clone(endTime)

	const roundName = 'Contribution'

	const event = {
		duration: '1',
		endDatetime: endDatetime.format('YYYYMMDDTHHmmssZ'),
		startDatetime: startDatetime.format('YYYYMMDDTHHmmssZ'),
		title: roundName,
	}

	const AddToCalendarDropdown = useMemo(
		() =>
			AddToCalendarHOC(
				({ onClick }) => (
					<ButtonAddCalendar onClick={onClick} ref={refButtonAddCalendar} />
				),
				({ ...props }) => (
					<SelectCalendar refButtonAddCalendar={refButtonAddCalendar} {...props} />
				),
			),
		[],
	)

	useEffect(() => {
		if (endRound || inRound) {
			dispatch(
				updateContributeInfoAction({
					endRound,
					inRound,
				}),
			)
		}
	}, [endRound, inRound])

	const percentFormat = endRound
		? 0
		: ((endTime - time.currentTime) / (endTime - startTime)) * 100

	if (isLoading) {
		return <></>
	}

	return (
		<div className={clsx(['bein-card d-flex flex-column', endRound && 'bg-disabled'])}>
			<div>
				<div
					className={clsx([
						'heading-300 text-neutral-5 bein-card-header ',
						endRound ? 'mb-0 bg-disabled' : '',
					])}
				>
					{roundName}
				</div>
			</div>
			<div className='bein-card-body h-100 d-flex flex-column justify-content-between'>
				{endRound && (
					<div className='heading-600 my-2 text-center h-100 d-flex align-items-center'>
						Đã hết. Thông tin vòng tiếp theo (dự kiến Q4/2023) sẽ được thông báo trên BIC Apps vào thời điểm phù hợp.
					</div>
				)}
				{!endRound && (
					<div>
						<div className='heading-200 text-center my-2'>
							{startRound && titleStartRound}
							{inRound && titleInRound}
							{/* {endRoundAndBicBalance && titleEndRound} */}
						</div>
						{!endRound && (
							<div>
								<CardTime time={time} isImg={isImg} />
							</div>
						)}
						{startRound && (
							<div className='d-flex justify-content-center my-4'>
								<AddToCalendarDropdown
									items={[SHARE_SITES.GOOGLE, SHARE_SITES.OUTLOOK]}
									className={'position-relative'}
									event={event}
								/>
							</div>
						)}
					</div>
				)}
				<div className='mt-4'>
					<Progress percent={percentFormat} status='exception' />
				</div>
			</div>
		</div>
	)
}

export default memo(RoundView)
