import bigDecimal from 'js-big-decimal'

export const formatNumber = (number = 0, maximumFractionDigits = 2, minimumFractionDigits = 2) => {
	return Number(number).toLocaleString('da-DK', {
		maximumFractionDigits,
		minimumFractionDigits,
	})
}
export const floorNumber = (number = 0, fractionDigits = 2) => {
	const num = new bigDecimal(number)
	return Number(num.round(fractionDigits, bigDecimal.RoundingModes.DOWN).getValue())
}
/**
 *  - number 1 > number 2 => 1
 *  - number 1 < number 2 => -1
 *  - number 1 = number 2 => 0
 */
export const compareBigDecimal = (number1 = 0, number2 = 0) => {
	return bigDecimal.compareTo(number1, number2)
}
export const convertNumberToHex = (number = 0) => {
	return `0x${number.toString(16)}`
}
