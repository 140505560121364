import {
  CLOSE_MODAL,
  CLOSE_MODAL_CONTRIBUTE,
  OPEN_MODAL,
  OPEN_MODAL_CONTRIBUTE,
  CLOSE_MODAL_MAINTENANCE,
  OPEN_MODAL_MAINTENANCE,
  UPDATE_CONTENT_MODAL,
  OPEN_MODAL_TABLE_RANKING,
  CLOSE_MODAL_TABLE_RANKING,
  OPEN_MODAL_AWARD_HISTORY,
  CLOSE_MODAL_AWARD_HISTORY,
} from "../types/Type";
import React from "react";

const initialState = {
  contentModal: <></>,
  contentModalContribute: "",
  handleSubmit: () => {},
  isModalVisible: false,
  isModalVisibleContribute: false,
  isModalMaintenance: false,
  isModalTableRanking: false,
  isModalAwardHistory: false,
  listDataRanking: [],
  Token: "",
  selectTime: "isoWeek",
  countTime: 0,
};

export const ModalReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_CONTENT_MODAL:
      return { ...state, contentModal: payload };
    case OPEN_MODAL: {
      return { ...state, isModalVisible: true, handleSubmit: payload };
    }
    case CLOSE_MODAL: {
      return { ...state, isModalVisible: false };
    }
    case OPEN_MODAL_CONTRIBUTE: {
      return {
        ...state,
        isModalVisibleContribute: true,
        contentModalContribute: payload,
      };
    }
    case CLOSE_MODAL_CONTRIBUTE: {
      return {
        ...state,
        isModalVisibleContribute: false,
      };
    }
    case OPEN_MODAL_MAINTENANCE: {
      return { ...state, isModalMaintenance: true };
    }
    case CLOSE_MODAL_MAINTENANCE: {
      return { ...state, isModalMaintenance: false };
    }
    case OPEN_MODAL_TABLE_RANKING: {
      return {
        ...state,
        isModalTableRanking: true,
        listDataRanking: payload.listDataRanking,
        Token: payload.Token,
      };
    }
    case CLOSE_MODAL_TABLE_RANKING: {
      return { ...state, isModalTableRanking: false };
    }

    case OPEN_MODAL_AWARD_HISTORY: {
      return {
        ...state,
        isModalAwardHistory: true,
        selectTime: payload.selectTime,
        countTime: payload.countTime,
      };
    }
    case CLOSE_MODAL_AWARD_HISTORY: {
      return { ...state, isModalAwardHistory: false };
    }

    default:
      return state;
  }
};
