import axios from 'axios'

export const getContributions = (address) => {
	return axios.get(process.env.REACT_APP_BSC_SCAN_API, {
		params: {
			module: 'account',
			action: 'tokentx',
			// contractaddress: process.env.REACT_APP_BUSD_ADDRESS,
			address: address,
			sort: 'desc',
			apikey: process.env.REACT_APP_BSC_SCAN_API_TOKEN,
		},
	})
}

export const getListContributeBNB = (address) => {
	return axios.get(process.env.REACT_APP_BSC_SCAN_API, {
		params: {
			module: 'account',
			action: 'txlist',
			address: address,
			sort: 'desc',
			apikey: process.env.REACT_APP_BSC_SCAN_API_TOKEN,
		},
	})
}

export const getListContributeInternalTx = (address) => {
	return axios.get(process.env.REACT_APP_BSC_SCAN_API, {
		params: {
			module: 'account',
			action: 'txlistinternal',
			address: address,
			sort: 'desc',
			apikey: process.env.REACT_APP_BSC_SCAN_API_TOKEN,
		},
	})
}
