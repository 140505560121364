import { Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import { UilExternalLinkAlt } from '@iconscout/react-unicons'
import { useGetListContributionHistoryQuery } from 'hooks/useGetListContributionHistoryQuery'
import { formatNumber } from 'utils/number'

const TransactionHistory = () => {
	const { data, isLoading } = useGetListContributionHistoryQuery()

	const listContributionHistory = data.listTransactionHistory

	let dataScroll = useRef(10)
	const scrollTableContribute = useRef(null)
	const [heightScroll, SetHeightScroll] = useState(550)
	const [widthWindow, setWidthWindow] = useState(window.innerWidth)
	useEffect(() => {
		const handleWindowResize = () => setWidthWindow(window.innerWidth)
		window.addEventListener('resize', handleWindowResize)
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [])
	useEffect(() => {
		if (widthWindow > 495) {
			SetHeightScroll(550)
		}
		if (widthWindow <= 495) {
			SetHeightScroll(300)
			dataScroll.current = 5
		}
	}, [widthWindow])
	const columns = [
		{
			title: 'STT',
			dataIndex: 'stt',
			width: '9%',
		},
		{
			title: 'Thời gian',
			width: '20%',
			render: (record, index) => {
				let myDate = new Date(1000 * record.time)
				return <span key={index}>{moment(myDate).format('DD/MM/YYYY H:mm:ss')}</span>
			},
			sorter: (a, b) => Number(a.time) - Number(b.time),
		},
		{
			title: <p className='text-right'>BNB</p>,
			width: '18%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-busd'>
						{record.BNB ? formatNumber(record.BNB) : '-'}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BUSD) - Number(b.BUSD),
		},
		{
			title: <p className='text-right'>BUSD</p>,
			width: '18%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-busd'>
						{formatNumber(record.BUSD)}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BUSD) - Number(b.BUSD),
		},
		{
			title: <p className='text-right'>BIC</p>,
			width: '25%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-bic'>
						{formatNumber(record.BIC)}{' '}
						{record.BICAirDrop > 0 ? `+ ${formatNumber(record.BICAirDrop)}` : ''}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BIC) - Number(b.BIC),
		},
		{
			title: <p className='text-right'>BGT</p>,
			width: '18%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-bgt'>
						{formatNumber(record.BGT)}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BGT) - Number(b.BGT),
		},

		{
			title: <p className='text-right'>Chi tiết</p>,
			width: '10%',
			align: 'right',
			render: (record) => {
				return (
					<a
						className='bein-antd-detail'
						target='_blank'
						rel='noopener noreferrer'
						href={record.addressHash}
					>
						<UilExternalLinkAlt className='link-color' size='20px' />
					</a>
				)
			},
		},
	]

	return (
		<div ref={scrollTableContribute} className='bein-card bein-card-history'>
			<div className='bein-card-header'>
				<h3>Lịch sử đóng góp</h3>
			</div>
			<div className='bein-card-body p-0'>
				<Table
					loading={isLoading}
					locale={{
						triggerDesc: 'Nhấn vào để xếp giảm dần',
						triggerAsc: 'Nhấn vào để xếp tăng dần',
						cancelSort: 'Nhấn vào để hủy sắp xếp',
					}}
					columns={columns}
					dataSource={listContributionHistory}
					rowKey='stt'
					pagination={false}
					scroll={
						listContributionHistory.length > dataScroll.current
							? { y: heightScroll }
							: false
					}
				/>
			</div>
		</div>
	)
}

export default TransactionHistory
