import React from 'react'
import { isMobile } from 'utils/common'

const ModalConnectError = () => {
	return (
		<div>
			<h2 className='heading-400 text-error-bein'>Kết nối với Metamask thất bại</h2>
			<p className='heading-100'>
				{isMobile()
					? 'Bạn cần sử dụng trình duyệt bên trong Metamask để kết nối'
					: 'Bạn chưa cài đặt Extension của Metamask.'}
				<br /> Đọc hướng dẫn tham gia Beincom Private Contribution{' '}
				<a
					className='link-color'
					target='_blank'
					href={process.env.REACT_APP_LINK_GUID_BEINCOM || ''}
					rel='noopener noreferrer'
				>
					tại đây
				</a>
			</p>
		</div>
	)
}

export default ModalConnectError
