import React from 'react'
import {
	BGT_ADDRESS,
	BIC_ADDRESS,
	BIR_ADDRESS,
	PRIVATE_SALE_CONTRACT_ADDRESS,
} from 'constants/contract'

export default function ContributeContract() {
	let preHref = process.env.REACT_APP_BSC_SCAN
	const linkContract = {
		smartContract: preHref + '/address/' + PRIVATE_SALE_CONTRACT_ADDRESS,
		bic: preHref + '/address/' + BIC_ADDRESS,
		bir: preHref + '/address/' + BIR_ADDRESS,
		bgt: preHref + '/address/' + BGT_ADDRESS,
	}

	const listAddress = [
		{
			name: 'BIC',
			link: linkContract.bic,
		},
		{
			name: 'BGT',
			link: linkContract.bgt,
		},
		{
			name: 'BIR',
			link: linkContract.bir,
		},
		{
			name: 'Contribution',
			link: linkContract.smartContract,
		},
	]

	return (
		<div
			className='bein-card bein-contract'
			style={{
				height: 'auto',
				backgroundColor: '#2F3945',
				borderRadius: '8px',
			}}
		>
			<div
				className='bein-card-header d-block d-sm-flex'
				style={{
					backgroundColor: '#2F3945',
					borderRadius: '8px',
				}}
			>
				<h3>Thông tin của Beincom Smart Contracts:</h3>
				<div className='d-flex justify-content-start align-items-center'>
					{listAddress.map((item, index) => {
						return (
							<a
								className='heading-100 infomation-wallet mb-0 link-color'
								target='_blank'
								rel='noopener noreferrer'
								href={item.link}
								key={index}
							>
								{item.name}
							</a>
						)
					})}
				</div>
			</div>
		</div>
	)
}
