import { useMutation } from '@tanstack/react-query'
import ModalConnectError from 'components/Modal/ModalConnectError'
import ModalNotConnect from 'components/Modal/ModalNotConnect'
import ModalWarning from 'components/Modal/ModalWarning'
import { closeModalAction } from 'redux/actions/ModalAction'
import { updateModalContentAction } from 'redux/actions/ModalAction'
import { openModalContributeAction } from 'redux/actions/ModalAction'
import { openModalAction } from 'redux/actions/ModalAction'
import { closeModalContributeAction } from 'redux/actions/ModalAction'
import { convertNumberToHex } from 'utils/number'
import Web3 from 'web3'
import React from 'react'
import PrivateSaleAbi from '../abi/PrivateSale.json'
import { updateInfoUserAction } from 'redux/actions/InfoUser'
import { PRIVATE_SALE_CONTRACT_ADDRESS } from 'constants/contract'
import { isMobile } from 'utils/common'

const checkSetupChain = async (dispatch) => {
	if (!window.ethereum) return
	const web3 = new Web3(window.ethereum)
	// check  setup chainId of user
	const rpcUrl = process.env.REACT_APP_BSC_ENDPOINT
	const explorerUrl = process.env.REACT_APP_BSC_SCAN
	const chainName = process.env.REACT_APP_CHAIN_NAME
	const web3FromUrl = new Web3(rpcUrl)
	const [chainIDFromUrl, chainIdCurrentUser] = await Promise.all([
		web3FromUrl.eth.getChainId(),
		web3.eth.getChainId(),
	])
	// if chainId of user is equal to chainId of contract then return methods
	if (chainIdCurrentUser === chainIDFromUrl) {
		return
	}
	const chainId = convertNumberToHex(chainIDFromUrl)
	// check if device is mobile show modal
	if (isMobile()) {
		dispatch(
			updateModalContentAction(
				<ModalWarning
					title='Cần chuyển sang mạng Binance smart chain để đóng góp.'
					content={
						<>
							<br /> Đọc hướng dẫn{' '}
							<a
								className='link-color'
								target='_blank'
								href={process.env.REACT_APP_LINK_GUID_BEINCOM}
								rel='noopener noreferrer'
							>
								tại đây
							</a>
						</>
					}
				/>,
			),
		)
		dispatch(openModalAction())
		return
	}
	// send request to server to check chainId of user
	const ethereum = window.ethereum
	try {
		// check if the chain to connect to is installed
		await ethereum.request({
			method: 'wallet_switchEthereumChain',
			params: [{ chainId }], // chainId must be in hexadecimal numbers
		})
	} catch (error) {
		// This error code indicates that the chain has not been added to MetaMask
		// if it is not, then install it into the user MetaMask
		if (error.code === 4902) {
			try {
				dispatch(openModalContributeAction(' Xác nhận chuyển đổi mạng lưới'))
				// send request to install the chain
				await ethereum.request({
					method: 'wallet_addEthereumChain',
					params: [
						{
							chainId,
							rpcUrls: [rpcUrl],
							chainName: chainName,
							nativeCurrency: {
								name: 'BNB',
								symbol: 'BNB',
								decimals: 18,
							},
							blockExplorerUrls: [explorerUrl],
						},
					],
				})
				dispatch(closeModalContributeAction())
			} catch (e) {
				dispatch(closeModalContributeAction())
				return
			}
		} else {
			dispatch(closeModalContributeAction())
			return
		}
	}
}

const connectWallet = async (dispatch) => {
	let web3
	let address
	let privateSaleContractOfAccount
	if (window.ethereum) {
		try {
			web3 = new Web3(window.ethereum)

			await window.ethereum.request({ method: 'eth_requestAccounts' })
			await dispatch(closeModalAction())

			const addresses = await web3.eth.getAccounts()

			address = addresses[0]

			privateSaleContractOfAccount = new web3.eth.Contract(
				PrivateSaleAbi,
				PRIVATE_SALE_CONTRACT_ADDRESS,
			)

			dispatch(
				updateInfoUserAction({
					address,
					privateSaleContractOfAccount,
					web3Account: web3,
				}),
			)
			checkSetupChain(dispatch)
		} catch (err) {
			console.log(err)
			dispatch(updateModalContentAction(<ModalNotConnect />))
			dispatch(openModalAction())
		}
	} else {
		dispatch(updateModalContentAction(<ModalConnectError />))
		dispatch(openModalAction())
	}

	return {
		web3,
		address,
		privateSaleContractOfAccount,
	}
}

export const useConnectWalletMutation = (dispatch) => {
	return useMutation(() => connectWallet(dispatch))
}
