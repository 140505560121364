import { OPEN_DOT_PUILSE } from "redux/types/Type";
import { CLOSE_DOT_PUILSE } from "redux/types/Type";
import { CLOSE_LOADING } from "redux/types/Type";
import { OPEN_LOADING } from "redux/types/Type";

export const openLoadingAction = () => ({
  type: OPEN_LOADING,
});
export const closeLoadingAction = () => ({
  type: CLOSE_LOADING,
});

export const openDotPulseAction = () => ({
  type: OPEN_DOT_PUILSE,
});
export const closeDotPulseAction = () => ({
  type: CLOSE_DOT_PUILSE,
});
