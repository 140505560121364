import React, { forwardRef, memo } from 'react'
import PropTypes from 'prop-types'

const ButtonAddCalendar = forwardRef(({ onClick},ref) => {
  return (
    <button ref={ref} className='btn-add-calender btn' onClick={onClick}>
      Thêm vào lịch
    </button>
  )
})
ButtonAddCalendar.displayName="ButtonAddCalendar"
export default memo(ButtonAddCalendar)
ButtonAddCalendar.propTypes = {
  onClick: PropTypes.any,
  setIsOpenListCalendar: PropTypes.any,
}
