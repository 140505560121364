import React, { memo, useEffect } from 'react'
import PropTypes from 'prop-types'
import ModalTableRanking from 'components/ModalTableRanking/ModalTableRanking'
import { useDispatch, useSelector } from 'react-redux'
import ModalAwardHistory from 'components/ModalAwardHistory/ModalAwardHistory'
import LeaderBoard from 'components/LeaderBoard/LeaderBoard'
import { updateListDataPersonalRankingAction } from 'redux/actions/ListDataRankingAction'

const ContributeTopRewards = (props) => {
  const { listRankingBGT, listRankingBIC } = useSelector(
    (state) => state.ListDataRankingReducer
  )
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(updateListDataPersonalRankingAction())
  }, [])
  return (
    <>
      <ModalTableRanking address={props.address} />
      <ModalAwardHistory address={props.address} />
      <div className='col-12 col-md-6 mb-4 mb-md-0 bein-card-rewards'>
      <LeaderBoard
          address={props.address}
          listDataRanking={listRankingBIC.all}
          idBody='bein-card-body-leaderboard-1'
          idUser='bein-id-user-1'
          title='Xếp hạng đóng góp BIC'
          token='BIC'
        />
      </div>
      <div className='col-12 col-md-6 bein-card-rewards'>
      <LeaderBoard
          address={props.address}
          listDataRanking={listRankingBGT.all}
          idBody='bein-card-body-leaderboard'
          idUser='bein-id-user'
          title='Xếp hạng đóng góp BGT'
          token='BGT'
        />
      </div>
    </>
  )
}
ContributeTopRewards.propTypes = {
  address: PropTypes.string,
}

export default memo(ContributeTopRewards)
