import { UPDATE_BIC_RATE } from 'redux/types/Type'
import { UPDATE_CONTRACT_INFO } from 'redux/types/Type'
import { UPDATE_ROUND_TIME } from 'redux/types/Type'

const initialState = {
	roundTime: {
		startTime: '',
		endTime: '',
	},
	contractInfo: {
		isPause: false,
		balance: {
			bic: 0,
			bir: 0,
		},
		contract: {
			bic: {},
			bir: {},
			bgt: {},
			whitelist: {},
			private: {},
		},
		address: {
			// owner: ownerContract,
			smartContract: '',
			bic: '',
			bir: '',
			bgt: '',
		},
	},
	bicRate: {
		currentPrice: 0,
		birBurningRate: 0,
		bicRateForCoreTeam: 0,
	},
}

export const InfoSmartContract = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_ROUND_TIME:
			return { ...state, roundTime: payload }
		case UPDATE_CONTRACT_INFO:
			return { ...state, contractInfo: payload }
		case UPDATE_BIC_RATE:
			return { ...state, bicRate: payload }
		default:
			return state
	}
}
