import { UPDATE_CONTRIBUTE_INFO } from 'redux/types/Type'

const initialState = {
	endRound: false,
	inRound: false,
}

export const ContributeInfoReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_CONTRIBUTE_INFO:
			return { ...state, ...payload }

		default:
			return state
	}
}
