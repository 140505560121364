import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import TagManager from 'react-gtm-module'

import 'antd/dist/antd.css'
import './styles/main.scss'

// core components
import Admin from 'layouts/Admin.js'

//setup redux , redux thunk
import { applyMiddleware, createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from 'redux/rootReducer'
import thunk from 'redux-thunk'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'

const store = createStore(rootReducer, applyMiddleware(thunk))

// Initialize Google tag manager
const gtmId = process.env.REACT_APP_BEIN_GTM_ID
if (gtmId) {
	TagManager.initialize({
		gtmId,
	})
}

const queryClient = new QueryClient({
	queries: {
		refetchOnWindowFocus: false, // default: true
		staleTime: Infinity,
	},
})

queryClient.setDefaultOptions({
	queries: {
		refetchOnWindowFocus: false, // default: true
		staleTime: Infinity,
		retry: 1,
	},
})

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<ReactQueryDevtools />
		<Provider store={store}>
			<BrowserRouter>
				<Switch>
					<Route path='/' component={Admin} />
				</Switch>
			</BrowserRouter>
		</Provider>
	</QueryClientProvider>,
	document.getElementById('root'),
)
