import React from "react";
import { Modal } from "antd";
import { useSelector } from "react-redux";

const ModalContribute = () => {
  const { contentModalContribute, isModalVisibleContribute } = useSelector(
    (state) => state.ModalReducer
  );

  return (
    <Modal
      className="bein-modal-contribute"
      visible={isModalVisibleContribute}
      footer={null}
    >
      <div className="modal-contribute-content">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          style={{
            margin: "auto",
            // background: "rgb(255, 255, 255)",
            display: "block",
            shapeRendering: "auto",
            background: "transparent",
          }}
          width="72px"
          height="72px"
          viewBox="0 0 100 100"
          preserveAspectRatio="xMidYMid"
        >
          <path
            d="M2 50A48 48 0 0 0 98 50A48 50 0 0 1 2 50"
            fill="#FFFFFF"
            stroke="none"
          >
            <animateTransform
              attributeName="transform"
              type="rotate"
              dur="1.1363636363636365s"
              repeatCount="indefinite"
              keyTimes="0;1"
              values="0 50 51;360 50 51"
            />
          </path>
        </svg>
        <img
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            height: "50px",
            width: "50px",
          }}
          src={require("../../assets/img/BIC-Violet2.png").default}
          alt="logo"
        />
      </div>
      <div style={{ padding: "16px" }}>
        <h3 className="heading-300 m-0">{contentModalContribute}</h3>
      </div>
    </Modal>
  );
};

export default ModalContribute;
