import React from "react";

const ModalLostNetWork = () => {
  return (
    <div>
      <h2 className="heading-400 text-error-bein">Mất kết nối mạng internet</h2>
      <p className="heading-100">
        Hệ thống không tìm thấy kết nối internet. Hãy kiểm tra cài đặt mạng và
        đường truyền internet của bạn.
        <br/>
        <span
          style={{ color: "#1890ff", cursor: "pointer", fontWeight: "bold" }}
          onClick={() => {
            window.location.reload();
          }}
        >
          Tải lại trang
        </span>
      </p>
    </div>
  );
};

export default ModalLostNetWork;
