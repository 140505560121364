import React from "react";

export default function Footer() {
  let fs = "9px";
  let isMacLike = navigator.platform.match(/(Mac)/i) ? true : false;
  if (isMacLike) {
    fs = "8px";
  }
  return (
    <footer className="text-center">
      <p className="heading-200">
        &copy; {1900 + new Date().getYear()} Beincom (BIC). All rights reserved.
      </p>
      <p
        className="heading-75"
        style={{
          color: "#77879c",
          fontSize: `${fs}`,
          marginTop: "9px",
          fontStyle: "italic",
        }}
      >
        <span style={{ fontWeight: "bold" }}>Cảnh báo rủi ro:</span> Giao dịch
        tài sản mã hóa là hoạt động chịu rủi ro thị trường cao. Hãy luôn giao
        dịch một cách cẩn trọng. Beincom và EVOL sẽ không chịu trách nhiệm cho
        những thiệt hại từ hoạt động giao dịch của bạn.
      </p>
    </footer>
  );
}
