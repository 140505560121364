import React from 'react'
import PropTypes from 'prop-types'
import { formatNumber } from 'utils/number'
import { useGetPaymentContractQuery } from 'hooks/useGetPaymentContractQuery'

export default function ContributeInfo() {
	const { data: paymentContract } = useGetPaymentContractQuery()
	const { currentPrice } = paymentContract || {}

	return (
		<div className='bein-card bein-info'>
			<div className='bein-card-header'>
				<h3>Tỉ lệ quy đổi</h3>
			</div>
			<div className='bein-card-body'>
				<div className='align-items-center'>
					<p className='text-left heading-200 content-price'>
						Tỉ lệ quy đổi hiện tại:
					</p>
					<div className='heading-800 mt-4'>
						1 BIC = {formatNumber(currentPrice, 5, 5)} BNB
					</div>
				</div>
				{/* <div className='row justify-content-between align-items-center mt-2'>
          <div
            className={`col-6 col-md-8 heading-200 content-price text-privateround ${className} pr-0`}
            style={{ fontStyle: 'italic' }}
          >
            Tỉ lệ quy đổi khi kết thúc Private Round:
          </div>
          <div
            className={`col-6 col-md-4 pl-0 heading-200 price-privateround text-privateround text-right ${className}`}
            style={{ fontStyle: 'italic' }}
          >
            1 BIC = {formatNumber(endPrice, 3, 3)} BUSD
          </div>
        </div> */}
			</div>
		</div>
	)
}

ContributeInfo.propTypes = {
	contractInfo: PropTypes.object,
	isConnect: PropTypes.bool,
}
