import React from "react";
import { Modal } from "antd";
import "./Modal.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { closeModalMaintenanceAction } from "redux/actions/ModalAction";

const ModalMaintenance = () => {
  const { isModalMaintenance } = useSelector((state) => state.ModalReducer);
  const dispatch = useDispatch()

  return (
    <Modal
      className="bein-modal bein-modal-maintenance"
      visible={isModalMaintenance}
      footer={null}
      style={{ top: "30%" }}
      onCancel={()=>{
        dispatch(closeModalMaintenanceAction())
      }}
    >
      <h2 className="heading-400 text-error-bein">
        Hệ thống đang tạm dừng để nâng cấp
      </h2>
      <p className="heading-100">
        Vui lòng xem thông báo trên app Discord tại kênh <br />
        <span style={{ fontWeight: "bold" }}> #bein-open-updates</span> để biết
        thêm thông tin chi tiết.
      </p>
    </Modal>
  );
};

export default ModalMaintenance;
