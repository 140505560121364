import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/queryKey'
import { useSelector } from 'react-redux'
import {
	getBGTContract,
	getBICContract,
	getBIRContract,
	getWeb3BSC,
	getWhitelistContract,
} from 'utils/contract'
import { floorNumber } from 'utils/number'
import Web3 from 'web3'

const syncUserInfo = async (address) => {
	const web3 = getWeb3BSC()

	const birContract = getBIRContract()
	const bgtContract = getBGTContract()
	const bicContract = getBICContract()
	const whitelistContract = getWhitelistContract()
	const [
		bnbBalance,
		bicBalance,
		birBalance,
		bgtBalance,
		userWhitelist = [],
	] = await Promise.all([
		web3.eth.getBalance(address),
		bicContract.methods.balanceOf(address).call(),
		birContract.methods.balanceOf(address).call(),
		bgtContract.methods.balanceOf(address).call(),
		whitelistContract.methods
			.getUserInfo(
				address,
				'0x289542b9a02c7937d659afe40404ec4f7813e59fc5caf7269eff9a359916cb5c',
			)
			.call(),
	])

	let isWhiteList = false
	let ref = null
	let listExtra = []
	if (userWhitelist) {
		if (userWhitelist[1]) {
			isWhiteList = true
		}
		ref = userWhitelist[0]
		listExtra = userWhitelist[2]
	}

	let isCoreTeam = false
	if (isWhiteList) {
		const userCoreTeam = await whitelistContract.methods
			.getUserInfo(
				address,
				'0xa02b4c230d71d2b2d883d893e4160247ac13d185cdc94c0e91dad6e97fa25bbb',
			)
			.call()
		if (userCoreTeam) {
			isCoreTeam = !!userCoreTeam[1]
		}
	}

	return {
		bnbBalance: floorNumber(Web3.utils.fromWei(bnbBalance)),
		bicBalance: Web3.utils.fromWei(bicBalance),
		birBalance: Web3.utils.fromWei(birBalance),
		bgtBalance: Web3.utils.fromWei(bgtBalance),
		isWhiteList,
		isCoreTeam,
		ref,
		listExtra,
	}
}

export const useGetUserInfoQuery = () => {
	const { address } = useSelector((state) => state.InfoUserReducer)
	const { data, ...rest } = useQuery(
		[QueryKeys.user_info[0], address || ''],
		() => syncUserInfo(address),
		{
			enabled: !!address,
			retry: 1,
		},
	)
	return {
		data: {
			...data,
			address,
		},
		...rest,
	}
}
