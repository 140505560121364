import { UPDATE_DATA_RANKING_USER } from 'redux/types/Type'
import { UPDATE_LIST_DATA_PERSONAL_RANKING } from 'redux/types/Type'
import { UPDATE_LIST_DATA_RANKING } from 'redux/types/Type'
import { getListRankDataUser } from 'services/RankingHistory'
import { getListRankData } from 'services/RankingHistory'
import { closeDotPulseAction, openDotPulseAction } from './LoadingAction'

export const updateListDataRankingAction = (startTimestamp, rankingType) => {
	return async (dispatch) => {
		try {
			await dispatch(openDotPulseAction())
			const { data, status } = await getListRankData(startTimestamp, rankingType)
			if (status === 200) {
				await dispatch(closeDotPulseAction())
				await dispatch({
					type: UPDATE_LIST_DATA_RANKING,
					payload: data,
				})
			}
		} catch (err) {
			console.log(err)
			setTimeout(() => {
				dispatch(updateListDataRankingAction(startTimestamp, rankingType))
			}, 100)
		}
	}
}

export const updateListDataPersonalRankingAction = () => {
	return async (dispatch) => {
		try {
			dispatch(openDotPulseAction())
			const { data, status } = await getListRankData()
			dispatch(closeDotPulseAction())
			if (status === 200) {
				dispatch({
					type: UPDATE_LIST_DATA_PERSONAL_RANKING,
					payload: data,
				})
			}
		} catch (err) {
			dispatch(closeDotPulseAction())
		}
	}
}

export const updateDataRankingUserAction = (address) => {
	return async (dispatch) => {
		try {
			const { data, status } = await getListRankDataUser(address)
			if (status === 200) {
				dispatch({
					type: UPDATE_DATA_RANKING_USER,
					payload: data,
				})
			}
		} catch (err) {
			//console.log("err user", err);
		}
	}
}
