import Modal from "antd/lib/modal/Modal";
import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { closeModalTableRankingAction } from "redux/actions/ModalAction";
import { Popconfirm } from "antd";
import moment from "moment";

const ModalTableRanking = (props) => {
  const address = props.address;
  const { isModalTableRanking, listDataRanking, Token } = useSelector(
    (state) => state.ModalReducer
  );
  const dispatch = useDispatch();

  const [content, setContent] = useState("");
  const [token, setToken] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [scrollCurrentUser, setIsScrollUser] = useState(null);
  const [viewportUser, setViewPortUser] = useState(true);
  const [characters, setCharacters] = useState();
  const [rankingType, setRankingType] = useState();
  const indexUser = listDataRanking?.findIndex(
    (item) => item.address?.toLowerCase() === address?.toLowerCase()
  );
  let arrowUser = "minus";
  let fontStt = "20px";
  let idCurrentUser;
  const isScrollCurrentUser = useRef(null);
  const scrollTimeOut = useRef(null);

  if(indexUser!==-1){
    arrowUser =
    listDataRanking[indexUser]?.changed < 0
      ? 'down'
      : listDataRanking[indexUser]?.changed === 0
      ? 'minus'
      : 'up'
  }

  if (listDataRanking?.length <= 999) {
    fontStt = "20px";
  }
  if (listDataRanking?.length > 999) {
    fontStt = "18px";
  }

  useEffect(() => {
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  useEffect(() => {
    if (Token === "BICW") {
      setRankingType("isoWeek");
      setContent("Xếp hạng tuần hiện tại (BIC)");
      setToken("BIC");
    }
    if (Token === "BICM") {
      setRankingType("month");
      setContent("Xếp hạng tháng hiện tại (BIC)");
      setToken("BIC");
    }
    if (Token === "BICA") {
      setRankingType("day");
      setContent("Xếp hạng toàn bộ (BIC)");
      setToken("BIC");
    }
    if (Token === "BGTW") {
      setRankingType("isoWeek");
      setContent("Xếp hạng tuần hiện tại (BGT)");
      setToken("BGT");
    }
    if (Token === "BGTM") {
      setRankingType("month");
      setContent("Xếp hạng tháng hiện tại (BGT)");
      setToken("BGT");
    }
    if (Token === "BGTA") {
      setRankingType("day");
      setContent("Xếp hạng toàn bộ (BGT)");
      setToken("BGT");
    }
  }, [Token]);

  useEffect(() => {
    setIsScrollUser(isScrollCurrentUser.current);
  }, [listDataRanking, isModalTableRanking]);

  useEffect(() => {
    scrollCurrentUser?.scrollIntoView({ block: "center" });
  }, [scrollCurrentUser,isModalTableRanking]);

  useEffect(() => {
    const elementUser = document.getElementById("bein-current-user-popup");
    setViewPortUser(isInViewport(elementUser));
    document.getElementById("bein-modal-body-2")?.addEventListener(
      "scroll",
      () => {
        if (scrollTimeOut.current) {
          clearTimeout(scrollTimeOut.current);
        }
        scrollTimeOut.current = setTimeout(() => {
          setViewPortUser(isInViewport(elementUser));
        }, 100);
      },
      {
        passive: true,
      }
    );
  }, [scrollCurrentUser]);

  useEffect(() => {
    if (width <= 700) {
      setCharacters(8);
    }
    if (width <= 480) {
      setCharacters(5);
    }
  }, [width]);

  const isInViewport = (el) => {
    if (el) {
      const rect = el.getBoundingClientRect();
      const viewportTop = document
        .getElementById("check-viewport-top-1")
        ?.getBoundingClientRect().top;
      const viewportBot = document
        .getElementById("check-viewport-bot-1")
        ?.getBoundingClientRect().top;
      const topUser = viewportTop - rect?.top;
      const botUser = viewportBot - rect?.top;
      if (topUser > 0) {
        return false;
      }
      if (botUser < 0) {
        return false;
      }
      if (topUser < 0 && botUser > 0) {
        return true;
      }
    }
  };

  const handleClose = () => {
    dispatch(closeModalTableRankingAction());
  };

  const confirm = (e, link) => {
    e.target.onClick = window.open(link, "_blank");
  };

  return (
    <Modal
      className="bein-modal-table-ranking"
      visible={isModalTableRanking}
      onCancel={handleClose}
      footer={null}
    >
      <div id="check-viewport-top-1"></div>
      <div className="bein-modal-header">
        <div className="row m-0 align-items-center">
          <div className="col-11 text-center">
            <h3 className="mb-0 heading-300 ml-0">{content}</h3>
          </div>
          <div
            className="col-1 pr-0"
            onClick={handleClose}
            style={{ cursor: "pointer" }}
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.40994 7.00019L12.7099 2.71019C12.8982 2.52188 13.004 2.26649 13.004 2.00019C13.004 1.73388 12.8982 1.47849 12.7099 1.29019C12.5216 1.10188 12.2662 0.996094 11.9999 0.996094C11.7336 0.996094 11.4782 1.10188 11.2899 1.29019L6.99994 5.59019L2.70994 1.29019C2.52164 1.10188 2.26624 0.996094 1.99994 0.996094C1.73364 0.996094 1.47824 1.10188 1.28994 1.29019C1.10164 1.47849 0.995847 1.73388 0.995847 2.00019C0.995847 2.26649 1.10164 2.52188 1.28994 2.71019L5.58994 7.00019L1.28994 11.2902C1.19621 11.3831 1.12182 11.4937 1.07105 11.6156C1.02028 11.7375 0.994141 11.8682 0.994141 12.0002C0.994141 12.1322 1.02028 12.2629 1.07105 12.3848C1.12182 12.5066 1.19621 12.6172 1.28994 12.7102C1.3829 12.8039 1.4935 12.8783 1.61536 12.9291C1.73722 12.9798 1.86793 13.006 1.99994 13.006C2.13195 13.006 2.26266 12.9798 2.38452 12.9291C2.50638 12.8783 2.61698 12.8039 2.70994 12.7102L6.99994 8.41019L11.2899 12.7102C11.3829 12.8039 11.4935 12.8783 11.6154 12.9291C11.7372 12.9798 11.8679 13.006 11.9999 13.006C12.132 13.006 12.2627 12.9798 12.3845 12.9291C12.5064 12.8783 12.617 12.8039 12.7099 12.7102C12.8037 12.6172 12.8781 12.5066 12.9288 12.3848C12.9796 12.2629 13.0057 12.1322 13.0057 12.0002C13.0057 11.8682 12.9796 11.7375 12.9288 11.6156C12.8781 11.4937 12.8037 11.3831 12.7099 11.2902L8.40994 7.00019Z"
                fill="#D5DCE5"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="row m-0">
        <div
          className="bein-modal-body pb-0 pt-0 px-0 w-100"
          id="bein-modal-body-2"
          style={{
            maxHeight: viewportUser || !props.address ? "364px" : "338px",
            overflowY: listDataRanking?.length > 7 ? "scroll" : "hidden",
          }}
        >
          <div className="row m-0">
            {listDataRanking?.length > 0 ? (
              <>
                {listDataRanking?.map((member, index) => {
                  let rankChangeBUSD = member.changed;
                  let arrow = "minus";
                  let isViewMe = false;
                  let linkBSCUser = `${process.env.REACT_APP_BSC_SCAN}/address/${member.address}`;
                  arrow =
                    rankChangeBUSD < 0
                      ? "down"
                      : rankChangeBUSD === 0
                      ? "minus"
                      : "up";
                  if (
                    member.address?.toLowerCase().trim() ===
                    address?.toLowerCase().trim()
                  ) {
                    isViewMe = true;
                    idCurrentUser = "bein-current-user-popup";
                  }
                  return (
                    <div
                      id={idCurrentUser}
                      ref={isViewMe ? isScrollCurrentUser : null}
                      className={`col-12 p-0 bein-ranking-bg-top ${
                        isViewMe
                          ? "pl-md-2 pr-0 pr-md-1 bein-ranking-member-me"
                          : "pl-md-3 pr-0 pr-md-2"
                      }`}
                      key={index}
                    >
                      <Popconfirm
                        placement="top"
                        title={
                          "Bạn có muốn chuyển sang trang BscScan của địa chỉ này không?"
                        }
                        onConfirm={(e) => confirm(e, linkBSCUser)}
                        okText="Có"
                        cancelText="Không"
                      >
                        <div
                          className={`row m-0 bein-ranking-member ${
                            isViewMe ? "px-0 px-md-2" : ""
                          } d-flex align-items-center justify-content-start`}
                        >
                          {index <= 98 ? (
                            <div
                              className="col p-0  d-flex justify-content-center"
                              style={{ maxWidth: "56px" }}
                            >
                              <img
                                src={
                                  require(`../../assets/img/logo/${
                                    index + 1
                                  }.png`).default
                                }
                                alt="logo-top-1"
                                style={{ width: "36px", height: "36px" }}
                              />
                            </div>
                          ) : (
                            <div
                              className="mb-0 col bein-ranking-stt p-0 text-center"
                              style={{ maxWidth: "56px", fontSize: fontStt }}
                            >
                              {index + 1}
                            </div>
                          )}
                          <div
                            className="col p-0 d-flex justify-content-start"
                            style={{ maxWidth: "20px" }}
                          >
                            <img
                              src={
                                require(`../../assets/img/icon/u_arrow-${arrow}.png`)
                                  .default
                              }
                              alt="logo-arrow"
                            />
                          </div>
                          <div className="col-5 col-sm-7 p-0 bein-ranking-member-address">
                            <div className="mb-0">
                              <div className="mb-0 heading-100 text-left">
                                {width > 700 ? (
                                  isViewMe ? (
                                    <span>{member.address} (Bạn)</span>
                                  ) : (
                                    member.address
                                  )
                                ) : isViewMe ? (
                                  <span>
                                    {" "}
                                    {member.address.slice(0, characters)}
                                    ...
                                    {member.address.slice(
                                      member.address.length - characters,
                                      member.address.length
                                    )}{" "}
                                    (Bạn)
                                  </span>
                                ) : (
                                  <span>
                                    {" "}
                                    {member.address.slice(0, characters)}
                                    ...
                                    {member.address.slice(
                                      member.address.length - characters,
                                      member.address.length
                                    )}{" "}
                                  </span>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col p-0 mr-1 text-right">
                            <p className="heading-100 mb-0 ">
                              {member.amount.toLocaleString("da-DK", {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              })}{" "}
                              <span className="body-25">{token}</span>
                            </p>
                          </div>
                        </div>
                      </Popconfirm>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="bein-no-data-ranking col-12 d-flex justify-content-center align-items-center flex-column p-4">
                {/* <div className="dot-pulse"></div> */}
                <svg
                  width={81}
                  height={91}
                  viewBox="0 0 81 91"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M40.5666 61.7383C54.7326 61.7383 66.2172 50.2537 66.2172 36.0371C66.2172 21.8205 54.682 10.3359 40.5666 10.3359C26.4006 10.3359 14.916 21.8205 14.916 36.0371C14.916 50.2537 26.4006 61.7383 40.5666 61.7383Z"
                    fill="#EAEEF9"
                  />
                  <path
                    d="M64.547 19.1896C65.6926 19.1896 66.6213 18.2609 66.6213 17.1153C66.6213 15.9697 65.6926 15.041 64.547 15.041C63.4014 15.041 62.4727 15.9697 62.4727 17.1153C62.4727 18.2609 63.4014 19.1896 64.547 19.1896Z"
                    fill="#EAEEF9"
                  />
                  <path
                    d="M67.5826 11.0949C68.365 11.0949 68.9992 10.4607 68.9992 9.67832C68.9992 8.89595 68.365 8.26172 67.5826 8.26172C66.8002 8.26172 66.166 8.89595 66.166 9.67832C66.166 10.4607 66.8002 11.0949 67.5826 11.0949Z"
                    fill="#EAEEF9"
                  />
                  <path
                    d="M16.2818 19.1389C17.0642 19.1389 17.6984 18.5046 17.6984 17.7223C17.6984 16.9399 17.0642 16.3057 16.2818 16.3057C15.4995 16.3057 14.8652 16.9399 14.8652 17.7223C14.8652 18.5046 15.4995 19.1389 16.2818 19.1389Z"
                    fill="#EAEEF9"
                  />
                  <path
                    d="M7.63083 46.4589C9.0838 46.4589 10.2617 45.2811 10.2617 43.8281C10.2617 42.3751 9.0838 41.1973 7.63083 41.1973C6.17786 41.1973 5 42.3751 5 43.8281C5 45.2811 6.17786 46.4589 7.63083 46.4589Z"
                    fill="#EAEEF9"
                  />
                  <g filter="url(#filter0_d_1945_164957)">
                    <path
                      d="M58.0205 23.1865L58.4252 55.7683C58.4252 56.8814 57.5146 57.7414 56.4015 57.7414H24.6292C23.5161 57.7414 22.6055 56.8308 22.6055 55.7683V13.8774C22.6055 12.7644 23.5161 11.9043 24.6292 11.9043H47.6489L58.0205 23.1865Z"
                      fill="url(#paint0_linear_1945_164957)"
                    />
                  </g>
                  <path
                    d="M38.0877 51.2146H36.9241C36.6711 51.2146 36.4688 50.9616 36.4688 50.6581C36.4688 50.3545 36.6711 50.1016 36.9241 50.1016H38.0877C38.3407 50.1016 38.5431 50.3545 38.5431 50.6581C38.5431 51.0122 38.3407 51.2146 38.0877 51.2146Z"
                    fill="#CED7E2"
                  />
                  <path
                    d="M34.5453 51.2146H27.7659C27.5129 51.2146 27.3105 50.9616 27.3105 50.6581C27.3105 50.3545 27.5129 50.1016 27.7659 50.1016H34.5453C34.7983 50.1016 35.0007 50.3545 35.0007 50.6581C35.0007 51.0122 34.7983 51.2146 34.5453 51.2146Z"
                    fill="#D5DDEA"
                  />
                  <path
                    d="M43.1967 18.7344H27.9683C27.6141 18.7344 27.3105 18.4308 27.3105 18.0767C27.3105 17.7225 27.6141 17.4189 27.9683 17.4189H43.1967C43.5509 17.4189 43.8544 17.7225 43.8544 18.0767C43.8544 18.4308 43.5509 18.7344 43.1967 18.7344Z"
                    fill="#D5DDEA"
                  />
                  <path
                    d="M33.837 22.3769H27.9683C27.6141 22.3769 27.3105 22.0734 27.3105 21.7192C27.3105 21.3651 27.6141 21.0615 27.9683 21.0615H33.7864C34.1406 21.0615 34.4441 21.3651 34.4441 21.7192C34.4441 22.0734 34.1406 22.3769 33.837 22.3769Z"
                    fill="#D5DDEA"
                  />
                  <path
                    d="M47.6504 11.9043V20.9098C47.6504 22.1746 48.7634 23.1865 50.0283 23.1865H58.0219"
                    fill="#D5DDEA"
                  />
                  <path
                    d="M60.4489 58.5009C59.943 58.5009 59.437 58.2985 59.0323 57.8432L50.5833 49.3942L50.2797 49.5965C47.5477 51.6203 44.3604 52.6827 41.1224 52.6827C37.2268 52.6827 33.3311 51.1143 30.4473 48.3823C27.4118 45.4985 25.7422 41.6029 25.7422 37.3531C25.7422 28.9041 32.6228 22.0234 41.0718 22.0234C46.8394 22.0234 51.8987 25.059 54.6307 30.2195C57.3121 35.3294 56.958 41.2993 53.6695 46.1056L53.4671 46.4092L61.9667 54.9088C62.8268 55.7689 62.6244 56.629 62.4726 57.0843C62.0679 57.8938 61.2584 58.5009 60.4489 58.5009ZM41.0718 26.2226C34.8995 26.2226 29.9414 31.2313 29.9414 37.3531C29.9414 44.3349 35.6584 48.5341 41.2236 48.5341C44.6133 48.5341 47.6995 47.0163 49.875 44.2843C52.5564 40.9452 53.0117 36.4424 51.1398 32.5468C49.2173 28.6511 45.3722 26.2226 41.0718 26.2226Z"
                    fill="#989FB0"
                  />
                  <path
                    d="M36.267 39.1745C36.9247 39.1745 37.4812 38.618 37.4812 37.9603C37.4812 37.3026 36.9247 36.7461 36.267 36.7461C35.6093 36.7461 35.0527 37.3026 35.0527 37.9603C35.0527 38.618 35.6093 39.1745 36.267 39.1745Z"
                    fill="#989FB0"
                  />
                  <path
                    d="M45.5756 39.1745C46.2333 39.1745 46.7898 38.618 46.7898 37.9603C46.7898 37.3026 46.2333 36.7461 45.5756 36.7461C44.9178 36.7461 44.3613 37.3026 44.3613 37.9603C44.3613 38.6686 44.9178 39.1745 45.5756 39.1745Z"
                    fill="#989FB0"
                  />
                  <path
                    d="M36.2198 33.9426L33.5488 35.3848L33.9094 36.0525L36.5804 34.6103L36.2198 33.9426Z"
                    fill="#989FB0"
                  />
                  <path
                    d="M45.4113 33.924L45.0508 34.5918L47.7221 36.0337L48.0826 35.3659L45.4113 33.924Z"
                    fill="#989FB0"
                  />
                  <path
                    d="M40.9225 42.0077C41.7048 42.0077 42.3391 41.532 42.3391 40.9453C42.3391 40.3585 41.7048 39.8828 40.9225 39.8828C40.1401 39.8828 39.5059 40.3585 39.5059 40.9453C39.5059 41.532 40.1401 42.0077 40.9225 42.0077Z"
                    fill="#989FB0"
                  />
                  <defs>
                    <filter
                      id="filter0_d_1945_164957"
                      x="0.605469"
                      y="0.904297"
                      width="79.8203"
                      height="89.8369"
                      filterUnits="userSpaceOnUse"
                      colorInterpolationFilters="sRGB"
                    >
                      <feFlood floodOpacity={0} result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset dy={11} />
                      <feGaussianBlur stdDeviation={11} />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1945_164957"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1945_164957"
                        result="shape"
                      />
                    </filter>
                    <linearGradient
                      id="paint0_linear_1945_164957"
                      x1="40.5037"
                      y1="10.844"
                      x2="40.5037"
                      y2="58.2356"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#FDFEFF" />
                      <stop offset="0.9964" stopColor="#ECF0F5" />
                    </linearGradient>
                  </defs>
                </svg>
                <p className="heading-75">Không có dữ liệu để hiển thị</p>
              </div>
            )}
          </div>
        </div>
        <div id="check-viewport-bot-1" style={{ width: "5px" }}></div>
        <div className="bein-card-footer p-0 w-100">
          <div
            className="row m-0"
            style={
              viewportUser || !props.address
                ? { display: "none" }
                : { display: "block", height: "50px" }
            }
          >
            {indexUser !== -1 ? (
              <div
                className="col-12 px-0 bein-leaderboard-user"
                style={{ height: "50px" }}
                onClick={() => {
                  const y = document.getElementById("bein-current-user-popup")
                    .offsetTop;
                  document
                    .getElementById("bein-modal-body-2")
                    ?.scrollTo({ top: y - 100, behavior: "smooth" });
                  setViewPortUser(true);
                }}
              >
                <div
                  className="row pl-0 pr-1 pr-md-0 px-md-3 m-0 bein-ranking-member bein-ranking-member-me-fix d-flex align-items-center justify-content-start"
                  style={{ height: "50px" }}
                >
                  {indexUser <= 98 && indexUser !== -1 ? (
                    <div
                      className="col p-0 d-flex justify-content-center"
                      style={{ maxWidth: "56px" }}
                    >
                      <img
                        src={
                          require(`../../assets/img/logo/${
                            indexUser + 1
                          }.png`).default
                        }
                        style={{ width: "36px", height: "36px" }}
                        alt="logo-top-1"
                      />
                    </div>
                  ) : (
                    <div
                      className="mb-0 col bein-ranking-stt p-0 text-center"
                      style={{ maxWidth: "56px", fontSize: fontStt }}
                    >
                      {indexUser + 1}
                    </div>
                  )}
                  <div
                    className="col p-0 d-flex justify-content-start"
                    style={{ maxWidth: "20px" }}
                  >
                    <img
                      src={
                        require(`../../assets/img/icon/u_arrow-${arrowUser}.png`)
                          .default
                      }
                      alt="logo-arrow"
                    />
                  </div>
                  <div className="col-4 col-sm-5 p-0 bein-ranking-member-address text-left">
                  <Popconfirm
                    className="heading-100 mb-0"
                        placement="top"
                        title={
                          "Bạn có muốn chuyển sang trang BscScan của địa chỉ này không?"
                        }
                        onConfirm={(e) => confirm(e, `${process.env.REACT_APP_BSC_SCAN}/address/${props.address}`)}
                        okText="Có"
                        cancelText="Không"
                      >
                      Bạn
                    </Popconfirm>
                  </div>
                  <div className="col p-0 text-right">
                    <p className="heading-100 mb-0 ">
                      {listDataRanking &&
                        listDataRanking[indexUser]?.amount.toLocaleString(
                          "da-DK",
                          {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          }
                        )}{" "}
                      <span className="body-25">{token}</span>
                    </p>
                  </div>
                </div>
              </div>
            ) : (
              <div
                className={`col-12 p-0 ${props.address ? "d-block" : "d-none"}`}
              >
                <div className="bein-no-ranking d-flex align-items-center justify-content-center">
                  <p className="heading-100 mb-0">Bạn không có xếp hạng</p>
                  <div className="bein-tooltip heading-100 mb-0">
                    <span>
                      {" "}
                      Bạn sẽ có xếp hạng khi đóng góp trong khoảng thời gian từ{" "}
                      {moment()
                        .startOf(rankingType)
                        .format("DD/MM/YYYY")} đến{" "}
                      {moment().endOf(rankingType).format("DD/MM/YYYY")}
                    </span>
                  </div>
                  <div className="bein-rectangle"></div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalTableRanking;

ModalTableRanking.propTypes = {
  address: PropTypes.string,
};
