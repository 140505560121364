import { UPDATE_LIST_DATA_PERSONAL_RANKING } from "redux/types/Type";
import { UPDATE_LIST_REWARDS_CONFIG } from "redux/types/Type";
import { UPDATE_TIME_STAMP } from "redux/types/Type";
import {
  UPDATE_LIST_DATA_RANKING,
  UPDATE_DATA_RANKING_USER,
} from "redux/types/Type";

const initialState = {
  listRankingBIC: [],
  listRankingBGT: [],
  listRankingBUSD: [],
  listRankingRefBUSD: [],
  rankingBICUser: {},
  rankingBGTUser: {},
  lastUpdate: "",
  nextUpdate: "",
  timeStamp: [],
  listRewardsConfig: {},
};

export const ListDataRankingReducer = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_LIST_DATA_RANKING: {
      return {
        ...state,
        listRankingBUSD: payload.buy,
        listRankingRefBUSD: payload.ref,
        lastUpdate: payload.LastUpdate,
        nextUpdate: payload.NextUpdate,
      };
    }
    case UPDATE_LIST_DATA_PERSONAL_RANKING: {
      return {
        ...state,
        listRankingBIC: payload.BIC,
        listRankingBGT: payload.BGT,
      };
    }

    case UPDATE_DATA_RANKING_USER:
      return {
        ...state,
        rankingBICUser: payload.BIC,
        rankingBGTUser: payload.BGT,
      };

    case UPDATE_LIST_REWARDS_CONFIG:
      return {
        ...state,
        listRewardsConfig: payload,
      };

    case UPDATE_TIME_STAMP:
      return {
        ...state,
        timeStamp: payload,
      };
    default:
      return state;
  }
};
