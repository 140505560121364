import { OPEN_DOT_PUILSE } from "redux/types/Type";
import { CLOSE_DOT_PUILSE } from "redux/types/Type";
import { CLOSE_LOADING } from "redux/types/Type";
import { OPEN_LOADING } from "redux/types/Type";

const initialState = {
  isLoading: true,
  isDotPulse: true,
};

export const LoadingReducer = (state = initialState, { type }) => {
  switch (type) {
    case OPEN_LOADING:
      return { ...state, isLoading: true };
    case CLOSE_LOADING:
      return { ...state, isLoading: false };
    case OPEN_DOT_PUILSE:
      return { ...state, isDotPulse: true };
    case CLOSE_DOT_PUILSE:
      return { ...state, isDotPulse: false };
    default:
      return state;
  }
};
