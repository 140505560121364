import { Table } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import Text from 'antd/lib/typography/Text'
import { UilExternalLinkAlt } from '@iconscout/react-unicons'
import { useGetListContributionHistoryQuery } from 'hooks/useGetListContributionHistoryQuery'
import { formatNumber } from 'utils/number'

const TransactionRefHistory = () => {
	const { data, isLoading } = useGetListContributionHistoryQuery()

	const listContributionHistory = data.listTransactionRefHistory

	const dataScroll = useRef(10)
	const [heightScroll, SetHeightScroll] = useState(550)
	const [widthWindow, setWidthWindow] = useState(window.innerWidth)
	const scrollTableRef = useRef(null)
	useEffect(() => {
		const handleWindowResize = () => setWidthWindow(window.innerWidth)
		window.addEventListener('resize', handleWindowResize)
		return () => window.removeEventListener('resize', handleWindowResize)
	}, [])
	useEffect(() => {
		if (widthWindow > 495) {
			SetHeightScroll(550)
		}
		if (widthWindow <= 495) {
			SetHeightScroll(300)
			dataScroll.current = 5
		}
	}, [widthWindow])
	const columns = [
		{
			title: 'STT',
			dataIndex: 'stt',
			width: '8%',
		},
		{
			title: 'Thời gian',
			width: '20%',
			render: (record, index) => {
				let myDate = new Date(1000 * record.time)
				return <span key={index}>{moment(myDate).format('DD/MM/YYYY H:mm:ss')}</span>
			},
			sorter: (a, b) => Number(a.time) - Number(b.time),
		},
		{
			title: <p className='text-right'>BNB</p>,
			width: '18%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-busd'>
						{record.BNB ? formatNumber(record.BNB, 4, 4) : '-'}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BUSD) - Number(b.BUSD),
		},
		{
			title: 'BIR',
			width: '16%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-bir'>
						{Number(record.BIR).toLocaleString('da-DK', {
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						})}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BIR) - Number(b.BIR),
		},
		{
			title: 'BIC',
			width: '18%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-bic'>
						{Number(record.BIC).toLocaleString('da-DK', {
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						})}{' '}
						{record.BICAirDrop > 0
							? `+ ${Number(record.BICAirDrop).toLocaleString('da-DK', {
									maximumFractionDigits: 2,
									minimumFractionDigits: 2,
							  })}`
							: ''}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BIC) - Number(b.BIC),
		},
		{
			title: 'BUSD',
			width: '15%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-bgt'>
						{Number(record.BUSD).toLocaleString('da-DK', {
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						})}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BUSD) - Number(b.BUSD),
		},
		{
			title: 'BGT',
			width: '15%',
			align: 'right',
			render: (record, index) => {
				return (
					<span key={index} className='bein-text-bgt'>
						{Number(record.BGT).toLocaleString('da-DK', {
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						})}
					</span>
				)
			},
			sorter: (a, b) => Number(a.BGT) - Number(b.BGT),
		},

		{
			title: <p className='text-right'>Chi tiết</p>,
			width: '9%',
			align: 'right',
			render: (record) => {
				return (
					<a
						className='bein-antd-detail'
						target='_blank'
						rel='noopener noreferrer'
						href={record.addressHash}
					>
						<UilExternalLinkAlt className='link-color' size='20px' />
					</a>
				)
			},
		},
	]
	return (
		<div ref={scrollTableRef} className='bein-card bein-card-history'>
			<div className='bein-card-header'>
				<h3>Lịch sử giới thiệu</h3>
			</div>
			<div className='bein-card-body p-0'>
				<Table
					loading={isLoading}
					locale={{
						triggerDesc: 'Nhấn vào để xếp giảm dần',
						triggerAsc: 'Nhấn vào để xếp tăng dần',
						cancelSort: 'Nhấn vào để hủy sắp xếp',
					}}
					columns={columns}
					dataSource={listContributionHistory}
					rowKey='time'
					pagination={false}
					scroll={
						listContributionHistory.length > dataScroll.current
							? { y: heightScroll }
							: false
					}
					summary={() => {
						let totalBIC = 0
						let totalBIR = 0
						let totalBGT = 0
						let totalBUSD = 0
						let totalBNB = 0
						listContributionHistory.forEach(({ BIC, BGT, BIR, BUSD, BNB }) => {
							totalBIC += Number(BIC)
							totalBGT += Number(BGT)
							totalBIR += Number(BIR)
							totalBUSD += Number(BUSD)
							totalBNB += Number(BNB)
						})
						if (listContributionHistory.length > 0) {
							return (
								<Table.Summary fixed>
									<Table.Summary.Row className='text-right'>
										<Table.Summary.Cell></Table.Summary.Cell>
										<Table.Summary.Cell className='text-left'>
											Tổng
										</Table.Summary.Cell>
										<Table.Summary.Cell>
											<Text style={{ color: '#47c287' }}>
												{totalBNB.toLocaleString('da-DK', {
													maximumFractionDigits: 4,
													minimumFractionDigits: 4,
												})}
											</Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell>
											<Text style={{ color: '#47c287' }}>
												{totalBIR.toLocaleString('da-DK', {
													maximumFractionDigits: 2,
													minimumFractionDigits: 2,
												})}
											</Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell>
											<Text style={{ color: '#47c287' }}>
												{totalBIC.toLocaleString('da-DK', {
													maximumFractionDigits: 2,
													minimumFractionDigits: 2,
												})}
											</Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell colSpan={1}>
											<Text style={{ color: '#BC9FF8' }}>
												{totalBUSD.toLocaleString('da-DK', {
													maximumFractionDigits: 2,
													minimumFractionDigits: 2,
												})}
											</Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell colSpan={1}>
											<Text style={{ color: '#BC9FF8' }}>
												{totalBGT.toLocaleString('da-DK', {
													maximumFractionDigits: 2,
													minimumFractionDigits: 2,
												})}
											</Text>
										</Table.Summary.Cell>
										<Table.Summary.Cell></Table.Summary.Cell>
									</Table.Summary.Row>
								</Table.Summary>
							)
						} else {
							return <></>
						}
					}}
				/>
			</div>
		</div>
	)
}
export default TransactionRefHistory
