const { useEffect, useState } = require('react')

export const useCountDownTimer = (lastTime, clear) => {
	const [time, setTime] = useState({
		days: 0,
		hours: 0,
		minutes: 0,
		seconds: 0,
		currentTime: 0,
	})

	useEffect(() => {
		const timer = setInterval(() => {
			CountDownTimer(lastTime)
		}, 1000)
		if (clear) {
			clearInterval(timer)
		}
		return () => clearInterval(timer)
	}, [lastTime, clear])

	const CountDownTimer = (timeEnd) => {
		const currentTime = new Date().getTime()

		let end = new Date(timeEnd)
		let _second = 1000
		let _minute = _second * 60
		let _hour = _minute * 60
		let _day = _hour * 24
		let now = new Date()
		let distance = end.getTime() - now
		setTime({
			...time,
			days: Math.floor(distance / _day),
			hours: Math.floor((distance % _day) / _hour),
			minutes: Math.floor((distance % _hour) / _minute),
			seconds: Math.floor((distance % _minute) / _second),
			currentTime,
		})
	}
	return time
}
