import React from 'react'
import PropTypes from 'prop-types'

const ModalWarning = ({ title, content }) => {
  return (
    <div>
      <h2
        className='heading-400 text-error-bein'
        style={{ lineHeight: '28px', marginBottom: '0px' }}
      >
        {title}
      </h2>
      <p className='heading-100'>{content}</p>
    </div>
  )
}

export default ModalWarning

ModalWarning.propTypes = {
  title: PropTypes.any,
  content: PropTypes.any,
}
