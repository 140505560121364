import Web3 from 'web3'
import PrivateSaleAbi from '../abi/PrivateSale.json'
import ManagementAbi from '../abi/Management.json'
import PaymentAbi from '../abi/Payment.json'
import BeinRightAbi from '../abi/BeinRight.json'
import {
	BGT_ADDRESS,
	BIC_ADDRESS,
	BIR_ADDRESS,
	MANAGEMENT_CONTRACT_ADDRESS,
	PAYMENT_CONTRACT_ADDRESS,
	PRIVATE_SALE_CONTRACT_ADDRESS,
	WHITE_LIST_ADDRESS,
} from 'constants/contract'
import BeinGiveTakeAbi from '../abi/BeinGiveTake.json'
import BeinChainAbi from '../abi/BeinChain.json'
import WhitelistAbi from '../abi/Whitelist.json'

export const getInfoRound = (startTime, endTime) => {
	const now = new Date()
	const inRound = now >= startTime && now <= endTime
	const endRound = now > endTime
	const startRound = now < startTime
	return {
		inRound,
		endRound,
		startRound,
	}
}

export const getWeb3BSC = () => {
	return new Web3(process.env.REACT_APP_BSC_ENDPOINT)
}

export const getPrivateSaleContract = () => {
	const web3 = getWeb3BSC()
	return new web3.eth.Contract(PrivateSaleAbi, PRIVATE_SALE_CONTRACT_ADDRESS)
}

export const getManagementContract = () => {
	const web3 = getWeb3BSC()
	return new web3.eth.Contract(ManagementAbi, MANAGEMENT_CONTRACT_ADDRESS)
}

export const getPaymentContract = () => {
	const web3 = getWeb3BSC()
	return new web3.eth.Contract(PaymentAbi, PAYMENT_CONTRACT_ADDRESS)
}

export const getBIRContract = (web3Account) => {
	const web3 = web3Account ?? getWeb3BSC()
	return new web3.eth.Contract(BeinRightAbi, BIR_ADDRESS)
}

export const getBGTContract = () => {
	const web3 = getWeb3BSC()
	return new web3.eth.Contract(BeinGiveTakeAbi, BGT_ADDRESS)
}

export const getBICContract = () => {
	const web3 = getWeb3BSC()
	return new web3.eth.Contract(BeinChainAbi, BIC_ADDRESS)
}

export const getWhitelistContract = () => {
	const web3 = getWeb3BSC()

	return new web3.eth.Contract(WhitelistAbi, WHITE_LIST_ADDRESS)
}
