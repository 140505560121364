import React from "react";
import { Modal } from "antd";
import "./Modal.css";
import { useDispatch, useSelector } from "react-redux";
import { openModalAction } from "redux/actions/ModalAction";
import { closeModalAction } from "redux/actions/ModalAction";

const ModalMessage = () => {
  const { isModalVisible, contentModal, handleSubmit } = useSelector(
    (state) => state.ModalReducer
  );

  const dispatch = useDispatch();

  const handleOk = () => {
    dispatch(openModalAction());
  };

  const handleCancel = () => {
    dispatch(closeModalAction());
  };

  return (
    <Modal
      className="bein-modal"
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      footer={null}
    >
      {contentModal}
      <button
        className="btn-modal-ok heading-200"
        onClick={() => {
          handleSubmit();
          handleCancel();
        }}
      >
        OK
      </button>
    </Modal>
  );
};

export default ModalMessage;
