import { useQuery } from '@tanstack/react-query'
import { AIRDROP_ADDRESS, BIC_ADDRESS, BIR_ADDRESS, BUSD_ADDRESS } from 'constants/contract'
import { QueryKeys } from 'constants/queryKey'
import { useSelector } from 'react-redux'
import {
	getContributions,
	getListContributeBNB,
	getListContributeInternalTx,
} from 'services/Contributions'
import { floorNumber } from 'utils/number'
import Web3 from 'web3'

const getListContributionHistory = async (address) => {
	const [
		resultContributions,
		resultBNBContributions,
		resultContributionInternalTx,
	] = await Promise.all([
		getContributions(address),
		getListContributeBNB(address),
		getListContributeInternalTx(address),
	])
	const { data: contributions } = resultContributions || {}
	const { data: bnbContributions } = resultBNBContributions || {}

	const { data: contributionsInternalTx } = resultContributionInternalTx || {}

	if (
		contributions.status == '1' ||
		bnbContributions.status == '1' ||
		contributionsInternalTx.status == '1'
	) {
		const listHistoryBGT = contributions?.result?.filter(
			(item) =>
				item.contractAddress.toLowerCase() ===
				process.env.REACT_APP_BGT_ADDRESS.toLowerCase(),
		)
		const listContributions = listHistoryBGT.map((dataBGT) => {
			let BIC = 0
			let BICAirDrop = 0
			let BGT = 0
			let BUSD = 0
			let time = 0
			let BIR = 0
			let addressHash = ''

			contributions?.result?.forEach((item) => {
				if (
					dataBGT.hash === item.hash &&
					item.to !== '0x0000000000000000000000000000000000000000'
				) {
					addressHash = dataBGT.hash
					time = item.timeStamp
					BGT = floorNumber(Web3.utils.fromWei(dataBGT.value), 3)
					if (item.contractAddress.toLowerCase() === BIC_ADDRESS.toLowerCase()) {
						BIC = (item.value / 1e18).toFixed(3)
						if (item.from.toLowerCase() === AIRDROP_ADDRESS) {
							BICAirDrop = floorNumber(Web3.utils.fromWei(item.value), 3)
						}
					}

					if (item.contractAddress.toLowerCase() === BUSD_ADDRESS.toLowerCase()) {
						BUSD = floorNumber(Web3.utils.fromWei(item.value), 3)
					}

					if (item.contractAddress.toLowerCase() === BIR_ADDRESS.toLowerCase()) {
						BIR = floorNumber(Web3.utils.fromWei(item.value), 3)
					}
				}
			})
			return {
				time: time,
				BIC: BIC,
				BGT: BGT,
				BUSD: BUSD,
				BIR: BIR,
				addressHash: `${process.env.REACT_APP_BSC_SCAN}/tx/${addressHash}`,
				BICAirDrop: BICAirDrop,
				hash: dataBGT.hash,
			}
		})

		return listContributions.map((contribution) => {
			const BNBSpend = floorNumber(
				Web3.utils.fromWei(
					bnbContributions?.result?.find(
						(valBNBContribution) => contribution.hash === valBNBContribution.hash,
					)?.value || '0',
				),
			)

			const BNBRef = floorNumber(
				Web3.utils.fromWei(
					contributionsInternalTx?.result?.find(
						(valBNBContribution) => contribution.hash === valBNBContribution.hash,
					)?.value || '0',
				),
				4,
			)

			return {
				...contribution,
				BNB: BNBSpend || BNBRef,
			}
		})
	}
}

export const useGetListContributionHistoryQuery = () => {
	const { address } = useSelector((state) => state.InfoUserReducer)

	const { data, ...rest } = useQuery(
		[QueryKeys.contribution_history[0], address],
		() => getListContributionHistory(address),
		{
			enabled: !!address,
		},
	)

	return {
		data: {
			listTransactionHistory:
				data
					?.filter((item) => item.BIC > 0 && (item.BUSD > 0 || item.BNB > 0))
					.map((item, index) => {
						return { ...item, stt: index + 1 }
					}) || [],
			listTransactionRefHistory:
				data
					?.filter((item) => {
						const isTransactionRefByBir =
							item.BIR > 0 &&
							(Number(item.BIR) === Number(item.BIC) ||
								Number(item.BUSD) === Number(item.BGT))

						const isTransactionRefByBNB =
							Number(item.BNB) > 0 && !Number(item.BIC)
						return isTransactionRefByBir || isTransactionRefByBNB
					})
					.map((item, index) => {
						return { ...item, stt: index + 1 }
					}) || [],
		},
		...rest,
	}
}
