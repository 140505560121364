import React, { useEffect } from 'react'
import { Switch, Route } from 'react-router-dom'
import Footer from './Footer'
import ContributePage from '../views/ContributePage/ContributePage.js'
import Loading from 'components/Loading/Loading.js'
import ModalMessage from '../components/Modal/ModalMessage.js'
import { useDispatch } from 'react-redux'
import { updateModalContentAction } from 'redux/actions/ModalAction.js'
import { openModalAction } from 'redux/actions/ModalAction.js'
import Header from './Header'
import ModalLostNetWork from '../components/Modal/ModalLostNetWork'
import ModalContribute from 'components/ModalContribute/ModalContribute'
import ModalMaintenance from 'components/ModalMaintenance/ModalMaintenance'
import { useConnectWalletMutation } from 'hooks/useConnectWalletMutation'
import { useGetUserInfoQuery } from 'hooks/useGetUserInfoQuery'

const switchRoutes = ({ isConnect, connectWallet, isWhiteList }) => (
	<Switch>
		{/* <Route path='/admin'>
			<AdminPage
				isConnect={isConnect}
				connectWallet={connectWallet}
				addWhiteList={addWhiteList}
				removeWhiteList={removeWhiteList}
				withdrawToken={withdrawToken}
				updatePrice={updatePrice}
			/>
		</Route> */}
		<Route path='/'>
			<Header
				isWhiteList={isWhiteList}
				connectWallet={connectWallet}
				isConnect={isConnect}
			/>
			<ModalContribute />
			<ModalMaintenance />
			<ModalMessage />
			<ContributePage isConnect={isConnect} />
			<Footer />
		</Route>
	</Switch>
)

export default function Admin() {
	const dispatch = useDispatch()

	const { mutate: connectWallet, data: connectWalletInfo } = useConnectWalletMutation(dispatch)

	const { web3, address } = connectWalletInfo || {}

	const {
		isInitialLoading: isLoadingGetUserInfo,
		data: userInfo,
		refetch: refetchUserInfoQuery,
	} = useGetUserInfoQuery(address)

	const { isWhiteList } = userInfo || {}

	useEffect(() => {
		const updateAddresses = () => {
			connectWallet()
			refetchUserInfoQuery()
		}
		window.ethereum?.on('accountsChanged', updateAddresses)
	}, [])

	useEffect(() => {
		const checkInternet = () => {
			if (!navigator.onLine) {
				dispatch(updateModalContentAction(<ModalLostNetWork />))
				dispatch(openModalAction())
			}
		}
		checkInternet()
		window.addEventListener('offline', checkInternet)
		return () => {
			window.removeEventListener('offline', checkInternet)
		}
	}, [])

	useEffect(() => {
		if (!address) {
			connectWallet()
		}
	}, [address])

	if (isLoadingGetUserInfo) {
		return <Loading />
	}

	return (
		<div className='bein-group'>
			<div className='bein-group-dark d-block container'>
				{switchRoutes({
					isConnect: !!web3 && address,
					connectWallet: connectWallet,
					isWhiteList,
				})}
			</div>
		</div>
	)
}
