import React from 'react'
import PropTypes from 'prop-types'
import { floorNumber } from 'utils/number'
import { formatNumber } from 'utils/number'
import { useGetUserInfoQuery } from 'hooks/useGetUserInfoQuery'

export default function ContributeBalance(props) {
	const { data: userInfo } = useGetUserInfoQuery()
	const {
		bnbBalance,
		bicBalance,
		birBalance,
		bgtBalance,
		isWhiteList,
		isCoreTeam,
		address,
	} = userInfo

	const preHref = process.env.REACT_APP_BSC_SCAN
	let linkContract = null

	if (address) {
		linkContract = {
			bicScanner: preHref + '/address/' + address,
		}
	}

	const listCoin = [
		{
			name: 'BNB',
			price: formatNumber(floorNumber(bnbBalance)),
		},
		{
			name: 'BIC',
			price: formatNumber(floorNumber(bicBalance)),
		},
		{
			name: 'BGT',
			price: formatNumber(floorNumber(bgtBalance)),
		},
		{
			name: 'BIR',
			price: formatNumber(floorNumber(birBalance)),
		},
	]

	const isShow = isWhiteList || isCoreTeam

	return (
		<div className='bein-card bein-card-right'>
			<div
				className='bein-card-header d-flex justify-content-between align-items-center'
				style={{ padding: '16px' }}
			>
				<h3>Ví Metamask của bạn</h3>
				{props.isConnect ? (
					<a
						target='_blank'
						rel='noopener noreferrer'
						href={address ? linkContract.bicScanner : '#'}
					>
						<span className='heading-200 text-title link-color'>Chi tiết</span>
					</a>
				) : (
					''
				)}
			</div>
			<div className='bein-card-body'>
				<div className='row bein-address'>
					<div className='col-12'>
						{props.isConnect ? (
							<div className='row'>
								<div
									className='col-12 col-sm-2 col-md-2 bein-address-title'
									style={{
										paddingTop: '11.5px',
										paddingBottom: '11.5px',
										paddingRight: '8px',
									}}
								>
									<span className='heading-100'>Địa chỉ</span>
								</div>
								<div className='col-12 col-sm-10 col-md-10 pl-0 form-input-address'>
									<div className='row content-input-price align-items-center m-0'>
										<div className='col-11 p-0 d-flex'>
											<div
												className={
													isShow
														? 'address-input-whitelist heading-75 d-none d-md-block'
														: 'address-input-whitelist address-input-NoWhitelist heading-75 d-md-block d-none'
												}
												style={{
													wordBreak: 'break-word',
												}}
											>
												{address}
											</div>
											{/* responsive */}
											<input
												disabled
												value={address}
												className={
													isShow
														? 'address-input-whitelist heading-75 d-block d-md-none'
														: 'address-input-whitelist address-input-NoWhitelist heading-75 d-block d-sm-none d-md-none'
												}
											/>
											{/* responsive */}
										</div>
										<div className='col-1 p-0 text-right text-md-center'>
											<span>
												{isShow ? (
													<svg
														width={20}
														height={20}
														viewBox='0 0 36 36'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<g filter='url(#filter0_d_75_102753)'>
															<path
																d='M2 17C2 8.16344 9.16344 1 18 1V1C26.8366 1 34 8.16344 34 17V17C34 25.8366 26.8366 33 18 33V33C9.16344 33 2 25.8366 2 17V17Z'
																fill='#36A16D'
															/>
															<path
																d='M25.8282 11.4117C25.7197 11.3024 25.5907 11.2156 25.4485 11.1564C25.3064 11.0971 25.1539 11.0667 24.9999 11.0667C24.8458 11.0667 24.6934 11.0971 24.5512 11.1564C24.409 11.2156 24.28 11.3024 24.1715 11.4117L15.4799 20.1151L11.8282 16.4517C11.7156 16.343 11.5827 16.2574 11.437 16.2C11.2913 16.1426 11.1358 16.1145 10.9792 16.1172C10.8227 16.1199 10.6682 16.1534 10.5246 16.2158C10.381 16.2782 10.2511 16.3683 10.1424 16.4809C10.0336 16.5935 9.94805 16.7265 9.89064 16.8721C9.83324 17.0178 9.80509 17.1733 9.8078 17.3299C9.8105 17.4864 9.84402 17.6409 9.90643 17.7845C9.96884 17.9281 10.0589 18.058 10.1715 18.1667L14.6515 22.6467C14.76 22.7561 14.889 22.8429 15.0312 22.9021C15.1734 22.9614 15.3258 22.9918 15.4799 22.9918C15.6339 22.9918 15.7864 22.9614 15.9285 22.9021C16.0707 22.8429 16.1997 22.7561 16.3082 22.6467L25.8282 13.1267C25.9466 13.0175 26.0411 12.8849 26.1058 12.7373C26.1704 12.5897 26.2038 12.4304 26.2038 12.2692C26.2038 12.1081 26.1704 11.9488 26.1058 11.8012C26.0411 11.6536 25.9466 11.521 25.8282 11.4117V11.4117Z'
																fill='white'
															/>
															<path
																d='M18 32C9.71573 32 3 25.2843 3 17H1C1 26.3888 8.61116 34 18 34V32ZM33 17C33 25.2843 26.2843 32 18 32V34C27.3888 34 35 26.3888 35 17H33ZM18 2C26.2843 2 33 8.71573 33 17H35C35 7.61116 27.3888 0 18 0V2ZM18 0C8.61116 0 1 7.61116 1 17H3C3 8.71573 9.71573 2 18 2V0Z'
																fill='white'
															/>
														</g>
														<defs>
															<filter
																id='filter0_d_75_102753'
																x={0}
																y={0}
																width={36}
																height={36}
																filterUnits='userSpaceOnUse'
																colorInterpolationFilters='sRGB'
															>
																<feFlood
																	floodOpacity={
																		0
																	}
																	result='BackgroundImageFix'
																/>
																<feColorMatrix
																	in='SourceAlpha'
																	type='matrix'
																	values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
																	result='hardAlpha'
																/>
																<feOffset dy={1} />
																<feGaussianBlur
																	stdDeviation={
																		1
																	}
																/>
																<feComposite
																	in2='hardAlpha'
																	operator='out'
																/>
																<feColorMatrix
																	type='matrix'
																	values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.125 0'
																/>
																<feBlend
																	mode='normal'
																	in2='BackgroundImageFix'
																	result='effect1_dropShadow_75_102753'
																/>
																<feBlend
																	mode='normal'
																	in='SourceGraphic'
																	in2='effect1_dropShadow_75_102753'
																	result='shape'
																/>
															</filter>
														</defs>
													</svg>
												) : (
													<svg
														width={20}
														height={20}
														viewBox='0 0 36 36'
														fill='none'
														xmlns='http://www.w3.org/2000/svg'
													>
														<g filter='url(#filter0_d_75_102700)'>
															<path
																d='M2 17C2 8.16344 9.16344 1 18 1V1C26.8366 1 34 8.16344 34 17V17C34 25.8366 26.8366 33 18 33V33C9.16344 33 2 25.8366 2 17V17Z'
																fill='#E43A4A'
															/>
															<g clipPath='url(#clip0_75_102700)'>
																<path
																	fillRule='evenodd'
																	clipRule='evenodd'
																	d='M17.0101 9.01005C17.2726 8.7475 17.6287 8.6 18 8.6C18.3713 8.6 18.7274 8.7475 18.99 9.01005C19.2525 9.2726 19.4 9.6287 19.4 10V17.5833C19.4 17.9546 19.2525 18.3107 18.99 18.5733C18.7274 18.8358 18.3713 18.9833 18 18.9833C17.6287 18.9833 17.2726 18.8358 17.0101 18.5733C16.7475 18.3107 16.6 17.9546 16.6 17.5833V10C16.6 9.6287 16.7475 9.2726 17.0101 9.01005ZM19.162 21.816C19.215 21.894 19.2574 21.9788 19.288 22.068C19.3381 22.1457 19.3759 22.2307 19.4 22.32C19.4069 22.4132 19.4069 22.5068 19.4 22.6C19.3978 22.7829 19.3597 22.9637 19.288 23.132C19.2251 23.3057 19.1248 23.4635 18.9942 23.5942C18.8635 23.7248 18.7057 23.8251 18.532 23.888C18.3644 23.9621 18.1832 24.0003 18 24.0003C17.8168 24.0003 17.6356 23.9621 17.468 23.888C17.2943 23.8251 17.1365 23.7248 17.0058 23.5942C16.8752 23.4635 16.7749 23.3057 16.712 23.132C16.6403 22.9637 16.6022 22.7829 16.6 22.6C16.5989 22.4157 16.6343 22.2331 16.7039 22.0625C16.7736 21.892 16.8763 21.7368 17.006 21.606C17.1421 21.4823 17.2983 21.3829 17.468 21.312C17.723 21.2048 18.004 21.1755 18.2755 21.2278C18.5471 21.2801 18.7971 21.4117 18.994 21.606L19.162 21.816Z'
																	fill='white'
																/>
															</g>
															<path
																d='M18 32C9.71573 32 3 25.2843 3 17H1C1 26.3888 8.61116 34 18 34V32ZM33 17C33 25.2843 26.2843 32 18 32V34C27.3888 34 35 26.3888 35 17H33ZM18 2C26.2843 2 33 8.71573 33 17H35C35 7.61116 27.3888 0 18 0V2ZM18 0C8.61116 0 1 7.61116 1 17H3C3 8.71573 9.71573 2 18 2V0Z'
																fill='white'
															/>
														</g>
														<defs>
															<filter
																id='filter0_d_75_102700'
																x={0}
																y={0}
																width={36}
																height={36}
																filterUnits='userSpaceOnUse'
																colorInterpolationFilters='sRGB'
															>
																<feFlood
																	floodOpacity={
																		0
																	}
																	result='BackgroundImageFix'
																/>
																<feColorMatrix
																	in='SourceAlpha'
																	type='matrix'
																	values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
																	result='hardAlpha'
																/>
																<feOffset dy={1} />
																<feGaussianBlur
																	stdDeviation={
																		1
																	}
																/>
																<feComposite
																	in2='hardAlpha'
																	operator='out'
																/>
																<feColorMatrix
																	type='matrix'
																	values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.125 0'
																/>
																<feBlend
																	mode='normal'
																	in2='BackgroundImageFix'
																	result='effect1_dropShadow_75_102700'
																/>
																<feBlend
																	mode='normal'
																	in='SourceGraphic'
																	in2='effect1_dropShadow_75_102700'
																	result='shape'
																/>
															</filter>
															<clipPath id='clip0_75_102700'>
																<rect
																	width={28}
																	height={28}
																	fill='white'
																	transform='translate(4 3)'
																/>
															</clipPath>
														</defs>
													</svg>
												)}
											</span>
										</div>
									</div>

									{isShow ? (
										<p
											className='heading-75 text-success'
											style={{ marginTop: '4px' }}
										>
											Địa chỉ thuộc danh sách whitelist
										</p>
									) : (
										<p
											className='heading-75 text-error'
											style={{ marginTop: '4px' }}
										>
											Địa chỉ ví của bạn chưa whitelist. Tham khảo
											hướng dẫn{' '}
											<a
												className='link-color d-block'
												target='_blank'
												rel='noopener noreferrer'
												href={
													process.env
														.REACT_APP_LINK_GUID_BEINCOM
												}
												style={{ color: '#4EA2FF' }}
											>
												<span>tại đây</span>
											</a>
										</p>
									)}
								</div>
							</div>
						) : (
							''
						)}
					</div>
					<div className='col-12 content-balance'>
						<div className='row m-0'>
							{listCoin.map((item, index) => {
								let classError = ''
								let classMg = ''
								if (
									item.name === 'BNB' &&
									bnbBalance <= 0 &&
									props.isConnect
								) {
									classError = 'input-price-error'
								}
								if ((index === 0 || index === 1) && !props.isConnect) {
									classMg = 'mt-0'
								}
								return (
									<div
										className={'col-6'}
										key={index}
										style={
											index % 2 === 0
												? {
														paddingLeft: '0px',
														paddingRight: '8px',
												  }
												: {
														paddingRight: '0px',
														paddingLeft: '8px',
												  }
										}
									>
										<div
											className={`bein-price heading-200 ${classError} ${classMg}`}
										>
											<p className='heading-200 content-balance-name'>
												{item.name}
											</p>
											<p className='heading-200 content-balance-price'>
												{item.price}
											</p>
										</div>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}

ContributeBalance.propTypes = {
	isConnect: PropTypes.any,
}
