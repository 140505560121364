import {
  CLOSE_MODAL,
  OPEN_MODAL,
  OPEN_MODAL_MAINTENANCE,
  CLOSE_MODAL_MAINTENANCE,
  UPDATE_BICTEMP,
  UPDATE_CONTENT_MODAL,
  CLOSE_MODAL_CONTRIBUTE,
  OPEN_MODAL_CONTRIBUTE,
  OPEN_MODAL_TABLE_RANKING,
  CLOSE_MODAL_TABLE_RANKING,
  OPEN_MODAL_AWARD_HISTORY,
  CLOSE_MODAL_AWARD_HISTORY,
} from '../types/Type'

export const openModalAction = (payload = () => {}) => ({
  type: OPEN_MODAL,
  payload,
})
export const closeModalAction = () => ({
  type: CLOSE_MODAL,
})
export const updateModalContentAction = (payload) => ({
  type: UPDATE_CONTENT_MODAL,
  payload,
})

export const openModalContributeAction = (payload) => ({
  type: OPEN_MODAL_CONTRIBUTE,
  payload,
})
export const closeModalContributeAction = (payload) => ({
  type: CLOSE_MODAL_CONTRIBUTE,
  payload,
})
export const updateBicTempAction = (payload) => ({
  type: UPDATE_BICTEMP,
  payload,
})

export const openModalMaintenanceAction = () => ({
  type: OPEN_MODAL_MAINTENANCE,
})

export const closeModalMaintenanceAction = () => ({
  type: CLOSE_MODAL_MAINTENANCE,
})

export const openModalTableRankingAction = (payload) => ({
  type: OPEN_MODAL_TABLE_RANKING,
  payload,
})

export const closeModalTableRankingAction = () => ({
  type: CLOSE_MODAL_TABLE_RANKING,
})

export const openModalAwardHistoryAction = (payload) => ({
  type: OPEN_MODAL_AWARD_HISTORY,
  payload,
})

export const closeModalAwardHistoryAction = () => ({
  type: CLOSE_MODAL_AWARD_HISTORY,
})
