import { UPDATE_LIST_DATA_AWARDS_HISTORY } from "redux/types/Type";

const initialState = {
  listDataAwardsHistory: {},
};

export const ListDataAwardsHistory = (
  state = initialState,
  { type, payload }
) => {
  switch (type) {
    case UPDATE_LIST_DATA_AWARDS_HISTORY:
      return { ...state, listDataAwardsHistory: payload };
    default:
      return state;
  }
};
