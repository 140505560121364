import { UPDATE_INFO_USER } from 'redux/types/Type'

const initialState = {
	address: '',
	privateSaleContractOfAccount: {},
	web3Account: {},
}

export const InfoUserReducer = (state = initialState, { type, payload }) => {
	switch (type) {
		case UPDATE_INFO_USER: {
			return {
				...state,
				...payload,
			}
		}

		default:
			return state
	}
}
