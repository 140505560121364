import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/queryKey'
import { getPaymentContract } from 'utils/contract'
import Web3 from 'web3'

const getDataPaymentContract = async () => {
	const contracts = getPaymentContract()
	const [
		rateBnbBicDenominator,
		rateBnbBicNumerator,
		birBurningRate,
		bicRateForCoreTeam,
		bnbThreshold,
	] = await Promise.all([
		contracts.methods.rateBnbBicDenominator().call(),
		contracts.methods.rateBnbBicNumerator().call(),
		contracts.methods.birBurningRate().call(),
		contracts.methods.bicRateForCoreTeam().call(),
		contracts.methods.bnbThreshold().call(),
	])

	return {
		currentPrice: Number(rateBnbBicDenominator / rateBnbBicNumerator),
		birBurningRate: Number(Web3.utils.fromWei(birBurningRate)),
		bicRateForCoreTeam: Number(Web3.utils.fromWei(bicRateForCoreTeam)),
		bnbThreshold: Number(Web3.utils.fromWei(bnbThreshold)),
	}
}

export const useGetPaymentContractQuery = () => {
	return useQuery(QueryKeys.payment_contract, () => getDataPaymentContract())
}
