import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

const TextField = forwardRef(
  (
    {
      error,
      value,
      onChange,
      disabled,
      onSubmit,
      title,
      placeholder,
      className,
      autoFocus = false,
      maxNumber = 6,
      type = "text",
      customSuffix,
    },
    ref
  ) => {
    return (
      <>
        <div className="heading-200 d-flex justify-content-between">
          {title}
        </div>
        <div
          className={clsx("d-flex justify-content-between input-wrap",{
            'input-error':error
          })}
          style={{ position: "relative" }}
        >
          <input
            id="bein-form-contribute"
            autoFocus={autoFocus}
            ref={ref}
            type={type}
            maxLength={maxNumber}
            placeholder={placeholder}
            className={clsx([
              error
                ? `input-price heading-400`
                : `input-price heading-400`,
              className,
            ])}
            value={value}
            onChange={onChange}
            disabled={disabled}
          />
          {customSuffix ||
            (onSubmit && (
              <button className="btn-max-busd" onClick={onSubmit}>
                TỐI ĐA
              </button>
            ))}
        </div>
        {error && (
          <div className="heading-75 text-error" style={{ marginTop: "4px" }}>
            {error}
          </div>
        )}
      </>
    );
  }
);
TextField.displayName = "TextField";
export default TextField;
TextField.propTypes = {
  error: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  onSubmit: PropTypes.func,
  title: PropTypes.any,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool,
  maxNumber: PropTypes.number,
  type: PropTypes.any,
  customSuffix: PropTypes.any,
};
