import { useQuery } from '@tanstack/react-query'
import { QueryKeys } from 'constants/queryKey'
import { getManagementContract } from 'utils/contract'
import { getInfoRound } from 'utils/contract'
import { convertTimeStampToDate } from 'utils/time'

const getDataPaymentContract = async () => {
	const contracts = getManagementContract()

	const [startTime, endTime] = await Promise.all([
		contracts.methods.startTime().call(),
		contracts.methods.endTime().call(),
	])
	return {
		startTime,
		endTime,
	}
}

export const useGetManagementContractQuery = () => {
	const { data, ...rest } = useQuery(QueryKeys.management_contract, () =>
		getDataPaymentContract(),
	)

	const startTime = convertTimeStampToDate(data?.startTime || 0)
	const endTime = convertTimeStampToDate(data?.endTime || 0)
	const { inRound, startRound, endRound } = getInfoRound(startTime, endTime)

	return {
		data: {
			startTime,
			endTime,
			inRound,
			startRound,
			endRound,
		},
		...rest,
	}
}
