import axios from 'axios'

export const getListRankData = (startTimestamp = '', rankingType = '') => {
	return axios({
		method: 'GET',
		url: `${process.env.REACT_APP_BEIN_API_LIST_RANKING}/leaderboard?startTimestamp=${startTimestamp}&rankingType=${rankingType}`,
	})
}
export const getListRankDataUser = (address) => {
	return axios({
		method: 'GET',
		url: `${process.env.REACT_APP_BEIN_API_LIST_RANKING}/user/rank?address=${address}`,
	})
}
