import React from 'react'
import confetti from 'canvas-confetti'
import PropTypes from 'prop-types'

const ModalBuySuccess = (props) => {
	let duration = 15 * 1000
	let animationEnd = Date.now() + duration
	let defaults = { startVelocity: 30, spread: 360, ticks: 60, zIndex: 0 }

	function randomInRange(min, max) {
		return Math.random() * (max - min) + min
	}

	let interval = setInterval(function () {
		let timeLeft = animationEnd - Date.now()

		if (timeLeft <= 0) {
			return clearInterval(interval)
		}

		let particleCount = 50 * (timeLeft / duration)
		// since particles fall down, start a bit higher than random
		confetti(
			Object.assign({}, defaults, {
				particleCount,
				origin: { x: randomInRange(0.1, 0.3), y: Math.random() - 0.2 },
			}),
		)
		confetti(
			Object.assign({}, defaults, {
				particleCount,
				origin: { x: randomInRange(0.7, 0.9), y: Math.random() - 0.2 },
			}),
		)
	}, 250)
	return (
		<div className='text-center'>
			<h2 className='heading-400 text-modal-success'>ĐÓNG GÓP THÀNH CÔNG</h2>
			<p className='heading-100 text-modal-success-content'>
				Chúc mừng bạn đã sở hữu thêm {props.BIC} BIC. 🥳
			</p>
		</div>
	)
}
ModalBuySuccess.propTypes = {
	BIC: PropTypes.string,
}
export default ModalBuySuccess
