import { combineReducers } from 'redux'
import { ContributeInfoReducer } from './reducers/ContributeInfoReducer'
import { InfoSmartContract } from './reducers/InfoSmartContract'
import { InfoUserReducer } from './reducers/InfoUserReducer'
import { ListDataAwardsHistory } from './reducers/ListDataAwardsHistory'
import { ListDataRankingReducer } from './reducers/ListDataRankingReducer'
import { LoadingReducer } from './reducers/LoadingReducer'
import { ModalReducer } from './reducers/ModalReducer'

const rootReducer = combineReducers({
  ModalReducer,
  ContributeInfoReducer,
  ListDataRankingReducer,
  InfoUserReducer,
  LoadingReducer,
  ListDataAwardsHistory,
  InfoSmartContract,
})

export default rootReducer
