import { useMutation, useQueryClient } from '@tanstack/react-query'
import ModalBuySuccess from 'components/Modal/ModalBuySucces'
import { PRIVATE_SALE_CONTRACT_ADDRESS } from 'constants/contract'
import { useDispatch, useSelector } from 'react-redux'
import {
	closeModalContributeAction,
	openModalAction,
	openModalContributeAction,
	updateModalContentAction,
} from 'redux/actions/ModalAction'
import { getBIRContract, getWeb3BSC } from 'utils/contract'
import Web3 from 'web3'
import React from 'react'
import { floorNumber } from 'utils/number'
import { QueryKeys } from 'constants/queryKey'

const contribute = async ({
	bnbSpent,
	birBurn,
	dispatch,
	address,
	privateSaleContractOfAccount,
	queryClient,
	web3Account,
}) => {
	try {
		if (privateSaleContractOfAccount) {
			// check BIR approve
			if (birBurn > 0) {
				const birContract = getBIRContract(web3Account)
				const allowanceBIR = await birContract.methods
					.allowance(address, PRIVATE_SALE_CONTRACT_ADDRESS)
					.call()
				if (allowanceBIR < birBurn * 1e18) {
					dispatch(openModalContributeAction('Xác nhận quyền BIR cho giao dịch'))
					await birContract.methods
						.approve(
							PRIVATE_SALE_CONTRACT_ADDRESS,
							'0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff',
						)
						.send({ from: address })
						.on('transactionHash', () => {
							dispatch(
								openModalContributeAction(
									'Đang xác nhận quyền BIR cho giao dịch của bạn...',
								),
							)
						})
				}
			}
			dispatch(openModalContributeAction('Xác nhận đóng góp của bạn trên Metamask'))
			const result = await privateSaleContractOfAccount.methods
				.purchase(Web3.utils.toWei(String(birBurn)))
				.send({ from: address, value: Web3.utils.toWei(String(bnbSpent)) })
				.on('transactionHash', () => {
					dispatch(openModalContributeAction('Đang gửi đóng góp của bạn...'))
				})

			console.log(result)
			if (result.status) {
				const keyEventFinal = Object.keys(result.events).length - 1
				const web3 = getWeb3BSC()
				const dataDecode = web3.eth.abi.decodeParameters(
					[
						'uint256',
						'uint256',
						'uint256',
						'uint256',
						'uint256',
						'uint256',
						'uint256',
						'uint256',
						'uint256',
						'uint256',
						'uint256',
					],
					result.events[keyEventFinal].raw.data,
				)
				// const bnbSpent = dataDecode[0]
				const bicForBnbSpending = dataDecode[3]
				const bicForBirBurning = dataDecode[4]
				const bicForBnbThreshold = dataDecode[8]
				const bicForCoreTeam = dataDecode[9]

				const BIC =
					floorNumber(Web3.utils.fromWei(bicForBnbSpending)) +
					floorNumber(Web3.utils.fromWei(bicForBirBurning)) +
					floorNumber(Web3.utils.fromWei(bicForBnbThreshold)) +
					floorNumber(Web3.utils.fromWei(bicForCoreTeam))

				dispatch(closeModalContributeAction())
				dispatch(
					updateModalContentAction(<ModalBuySuccess BIC={floorNumber(BIC, 3, 3)} />),
				)
				dispatch(openModalAction())
				await Promise.all([
					queryClient.invalidateQueries(QueryKeys.contribution_history),
					await queryClient.invalidateQueries(QueryKeys.user_info),
				])
			}
			// load history
		}
	} catch (err) {
		console.log(err)
		dispatch(closeModalContributeAction())
	}
}

export const useContributeMutation = () => {
	const dispatch = useDispatch()
	const queryClient = useQueryClient()
	const { address, privateSaleContractOfAccount, web3Account } = useSelector(
		(state) => state.InfoUserReducer,
	)

	return useMutation(({ bnbSpent, birBurn }) =>
		contribute({
			bnbSpent,
			birBurn,
			dispatch,
			address,
			privateSaleContractOfAccount,
			queryClient,
			web3Account,
		}),
	)
}
