export const OPEN_LOADING = 'OPEN_LOADING'
export const CLOSE_LOADING = 'CLOSE_LOADING'
export const UPDATE_CONTENT_MODAL = 'UPDATE_CONTENT_MODAL'
export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'
export const OPEN_MODAL_CONTRIBUTE = 'OPEN_MODAL_CONTRIBUTE'
export const CLOSE_MODAL_CONTRIBUTE = 'CLOSE_MODAL_CONTRIBUTE'
export const OPEN_MODAL_MAINTENANCE = 'OPEN_MODAL_MAINTENANCE'
export const CLOSE_MODAL_MAINTENANCE = 'CLOSE_MODAL_MAINTENANCE'
export const OPEN_MODAL_TABLE_RANKING = 'OPEN_MODAL_TABLE_RANKING'
export const CLOSE_MODAL_TABLE_RANKING = 'CLOSE_MODAL_TABLE_RANKING'
export const OPEN_MODAL_AWARD_HISTORY = 'OPEN_MODAL_AWARD_HISTORY'
export const CLOSE_MODAL_AWARD_HISTORY = 'CLOSE_MODAL_AWARD_HISTORY'
export const UPDATE_BICTEMP = 'UPDATE_BICTEMP'

export const UPDATE_LIST_DATA_RANKING = 'UPDATE_LIST_DATA_RANKING'
export const UPDATE_DATA_RANKING_USER = 'UPDATE_DATA_RANKING_USER'

export const UPDATE_INFO_USER = 'UPDATE_INFO_USER'

export const UPDATE_LIST_DATA_AWARDS_HISTORY = 'UPDATE_LIST_DATA_AWARDS_HISTORY'
export const UPDATE_LIST_DATA_PERSONAL_RANKING = 'UPDATE_LIST_DATA_PERSONAL_RANKING'
export const UPDATE_LIST_REWARDS_CONFIG = 'UPDATE_LIST_REWARDS_CONFIG'

export const UPDATE_TIME_STAMP = 'UPDATE_TIME_STAMP'

export const OPEN_DOT_PUILSE = 'OPEN_DOT_PUILSE'
export const CLOSE_DOT_PUILSE = 'CLOSE_DOT_PUILSE'

//Info smart contract PS2
export const UPDATE_ROUND_TIME = 'UPDATE_ROUND_TIME'
export const UPDATE_CONTRACT_INFO = 'UPDATE_CONTRACT_INFO'
export const UPDATE_BIC_RATE = 'UPDATE_BIC_RATE'
export const UPDATE_CONTRIBUTE_INFO = 'UPDATE_CONTRIBUTE_INFO'
