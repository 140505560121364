import React, { memo } from 'react'
import PropTypes from 'prop-types'

const CardTime = ({ time, isImg = true }) => {
	return (
		<div className='row bein-group-ranking-time-content px-1'>
			<div className='col px-0 py-1 text-center'>
				<div className='bein-ranking-time-items'>
					<div className=' heading-600'>
						{time.days < 0 || !time.days ? 0 : time.days}
					</div>
					<span className='body-75'>Ngày</span>
					<div className='line'></div>
				</div>
			</div>
			<div className='col px-0 py-1 text-center'>
				<div className='bein-ranking-time-items' style={{ borderRadius: 0 }}>
					<div className=' heading-600'>
						{time.hours < 0 || !time.hours ? 0 : time.hours}
					</div>
					<span className='body-75'>Giờ</span>
					<div className='line'></div>
				</div>
			</div>
			<div className='col px-0 py-1 text-center'>
				<div className='bein-ranking-time-items' style={{ borderRadius: 0 }}>
					<div className=' heading-600'>
						{time.minutes < 0 || !time.minutes ? 0 : time.minutes}
					</div>
					<span className='body-75'>Phút</span>
					{isImg && <div className='line'></div>}
				</div>
			</div>
			{isImg && (
				<div className='col px-0 py-1 text-center'>
					<div
						className='bein-ranking-time-items'
						style={{ borderRadius: '0px 4px 4px 0px' }}
					>
						<div className=' heading-600'>
							{time.seconds < 0 || !time.seconds ? 0 : time.seconds}
						</div>
						<span className='body-75'>Giây</span>
					</div>
				</div>
			)}
		</div>
	)
}

export default memo(CardTime)
CardTime.propTypes = {
	time: PropTypes.any,
	isImg: PropTypes.bool,
}
