import React, { memo } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { useClickOutside } from 'hooks/comcom'

const SelectCalendar = ({
  children,
  isOpen,
  onRequestClose,
  refButtonAddCalendar,
}) => {

  useClickOutside(refButtonAddCalendar, (e) => {
    if (isOpen) {
      setTimeout(() => {
        onRequestClose(e)
      },100)
    }
})
  return <div className={clsx([
    'flex-column dropdown-menu',
    !isOpen ? 'd-none' : 'd-flex',
  ])}
  >
        {children}
  </div>
}

export default memo(SelectCalendar)
SelectCalendar.propTypes = {
  children: PropTypes.any,
  refButtonAddCalendar: PropTypes.any,
  isOpen: PropTypes.any,
  onRequestClose: PropTypes.any,
}
